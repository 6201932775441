import LazyComponent from "@components-core/LazyComponent";
import PureComponent from "@components-core/PureComponent";
import Root from "@components-core/Root";
import ScrollRestoration from "@components-core/ScrollRestoration";
import ScrollToTop from "@components-core/ScrollToTop";
import { connectHOCs } from "@components-utils";
import { EVENT_APP_FORCE_UPDATE, EVENT_PAGE_ADMIN_MAXIMIZE } from "@constants";
import HelmetProps from "@prop-types/HelmetProps";
import { errorAddUnhandledException } from "@redux-actions/error";
import { pageLoad } from "@redux-actions/page";
import { PageBodyBS, PageHeaderBS } from "@style-variables";
import FooterTemplate, {
  TEMPLATE_KEY as FOOTER_TEMPLATE_KEY
} from "@templates/common/FooterTemplate";
import HeaderTemplate, {
  TEMPLATE_KEY as HEADER_TEMPLATE_KEY
} from "@templates/common/HeaderTemplate";
import {
  mapValueToTemplates,
  widgetsAsTemplateItems
} from "@templates/common/utils";
import { debug } from "@utils/debug";
import { removeTrailingSlash } from "@utils/filesystem";
import { toHelmetJSX } from "@utils/functions";
import PropTypes from "prop-types";
import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import LayoutItem from "./components/Layout/Item";
import { mergeFeatures, mergeHelmet } from "./screens/utils";

// What is Puppeteer: https://developers.google.com/web/tools/puppeteer/
// SSR via Puppeteer: https://developers.google.com/web/tools/puppeteer/articles/ssr
// SSR via Puppeteer: https://toolbox.kurio.co.id/cheating-react-server-side-rendering-with-puppeteer-631e9630725b
// read this: https://github.com/GoogleChromeLabs/webpack-libs-optimizations
// for production: https://www.npmjs.com/package/babel-plugin-transform-react-remove-prop-types <<< NO-NO as reac-bootstrap relies on propTypes
// https://www.npmjs.com/package/babel-plugin-transform-imports

class App extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { pageMaximized: false };

    // remove the eventual preloader banner element from DOM
    var pageLoader = document.getElementById("pageLoader");
    if (pageLoader) {
      pageLoader.parentElement.removeChild(pageLoader);
    }

    this.handleAppForceUpdate = this.handleAppForceUpdate.bind(this);

    this.handleAdminPageMaximize = this.handleAdminPageMaximize.bind(this);

    this.pageBodyRef = React.createRef();
  }

  /**
   * @description Handle the DEV request to rerender the pictures due to eg. `__LAYOUT_WIREFRAME__` toggling
   * @memberof App
   */
  handleAppForceUpdate() {
    this.forceUpdate();
  }

  handleAdminPageMaximize(e) {
    this.setState({ pageMaximized: !this.state.pageMaximized }, () => {
      const elements = [
        this.pageBodyRef.current,
        document.querySelector("." + PageHeaderBS)
      ].filter(Boolean);

      elements.forEach(el => {
        const className = "maximized";

        if (this.state.pageMaximized) {
          el.classList.add(className);
        } else {
          el.classList.remove(className);
        }
      });
    });
  }

  // see https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
  UNSAFE_componentWillMount() {
    window.__APP_BS__ = this.props.className;

    this.routes = this.getRoutes();
    this.redirects = this.getRedirects();

    const routeErrors = this.props.pathfinder.errors();

    if (routeErrors.length) {
      routeErrors.forEach(error =>
        this.props.store.dispatch(
          errorAddUnhandledException(
            {
              message: error.message,
              stack: ` (${new Date().toLocaleString()})`
            },
            this.props.i18n.UNEXPECTED_ERROR_CAUSE.context.ROUTE
          )
        )
      );
    }
  }

  componentDidMount() {
    // add a listener for a custom event that would require to re-render the App forcebly
    document.addEventListener(
      EVENT_APP_FORCE_UPDATE,
      this.handleAppForceUpdate
    );

    // add a listener for a custom event that would maximize the admin page toolbox
    document.addEventListener(
      EVENT_PAGE_ADMIN_MAXIMIZE,
      this.handleAdminPageMaximize
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      EVENT_PAGE_ADMIN_MAXIMIZE,
      this.handleAdminPageMaximize
    );

    document.removeEventListener(
      EVENT_APP_FORCE_UPDATE,
      this.handleAppForceUpdate
    );
  }

  /**
   * @description Render the application Root component
   * @param {Object} screen - The screen the Root wraps
   * @returns {JSX}
   * @memberof App
   */
  renderAppRoot(screen) {
    const helmet = mergeHelmet(this.props.helmet, screen.helmet, screen);

    const { get, BOOL_ON_NOTFOUND } = this.props.pathfinder;

    // hide breadcrumbs on admin-toolbox
    const breadcrumbs =
      removeTrailingSlash(window.location.pathname) !==
      get("admin-toolbox", null, BOOL_ON_NOTFOUND);

    const homePath = removeTrailingSlash(this.props.homePageDef.path);

    return (
      <Root
        screen={screen}
        siteId={this.props.siteId}
        homePath={homePath}
        breadcrumbs={breadcrumbs}
        i18n={this.props}
      >
        <Helmet prioritizeSeoTags>{toHelmetJSX(helmet)}</Helmet>
      </Root>
    );
  }

  /**
   * @description Get the lazy-loading component for the route
   * @param {Promise} screen - The promise that resolves the screen factory
   * @param {Object} helmet - The screen's specific helmet
   * @param {Object} features  - The screen's specific features
   * @returns {JSX}
   * @memberof App
   */
  getRouteLazyComponent(screen, helmet, features) {
    const loader = () =>
      screen.then(module => {
        const screen = module.default(this.props.siteContext);

        const screenKey = Object.keys(screen).pop();

        // https://reactjs.org/docs/code-splitting.html#reactlazy
        const resolver = {
          default: () =>
            this.renderAppRoot({
              screenKey,
              ...screen[screenKey],
              helmet: mergeHelmet(helmet, screen[screenKey].helmet, screen),
              features: mergeFeatures(features, screen[screenKey].features)
            })
        };

        return resolver;
      });

    return <LazyComponent lazy={{ componentName: "Root", loader }} />;
  }

  /**
   * @description Get the component for the page
   * @param {Object} page - The page
   * @returns {JSX}
   * @memberof App
   */
  getRouteComponent(page) {
    debug(
      `Building the route component for %c"${page.key}"`,
      "debug",
      "color:white"
    );

    // "async" dispatcher, a workaround for setting the store state while rendering the AppRoot
    // if it doesn't work then increase little bit the timeout interval
    const asyncDispatch = action => dispatch =>
      setTimeout(() => {
        return dispatch(action);
      }, 1);

    this.props.store.dispatch(asyncDispatch(pageLoad(page)));

    try {
      const screen =
        "function" === typeof page.screen
          ? page.screen(page.helmet)
          : page.screen;

      // render the Root async via React.Suspense
      if (screen instanceof Promise) {
        return this.getRouteLazyComponent(screen, page.helmet, page.features);
      }

      // render the Root sync
      return this.renderAppRoot({
        screenKey: page.key,
        ...screen,
        helmet: mergeHelmet(page.helmet, screen.helmet, screen),
        features: mergeFeatures(page.features, screen.features)
      });
    } catch (error) {
      debug(error, "error");

      const errorPage = this.props.pages.find(
        item =>
          item !== page && this.props.httpErrors["404-screen"] === item.key
      );

      if (errorPage) {
        return this.getRouteComponent(errorPage);
      }

      throw error;
    }
  }

  logRouteToConsole(name, path, type) {
    const logColor = [
      "color: lightgreen; font-weight:600",
      "color:",
      "color: white; font-weight:600",
      "color:",
      "color: aqua"
    ];

    debug(
      `Creating named route %c${name}%c for path "%c${path}%c" %c(${type})"`,
      "log",
      logColor
    );
  }

  logRedirectsToConsole(from, to) {
    const logColor = [
      "color: aqua; font-weight:600",
      "color:",
      "color: white; font-weight:600"
    ];

    debug(`Creating redirect from %c${from}%c to "%c${to}"`, "log", logColor);
  }

  /**
   * @description Get the application routes
   * @returns {array} Returns an array of Route
   * @memberof App
   */
  getRoutes() {
    const result = [];

    this.props.pages.forEach(page => {
      const AppRoot = () => this.getRouteComponent(page);

      const objRoute = path => (
        <Route {...path} key={result.length} component={AppRoot} />
      );

      const strRoute = path => (
        <Route
          key={result.length}
          exact={path === this.props.homePageDef.path}
          path={path}
          component={AppRoot}
        />
      );

      const path = page.path;
      const name = page.key;

      const pushRoute = (name, route, path, logType) => {
        this.logRouteToConsole(name, path, logType);

        result.push(route);
      };

      // the supported route.path:
      // - a string: "/some-path/:some-parameter"
      // - an array of strings: ["/some-path/:parameter", "/other-path/:other-parameter"]
      // - an object: {path: "/some-path/:parameter", exact:true}
      // - an array of objects: [{path: "/some-path/:parameter", exact:true}, {path: "/some-path/path-or-paraeter/:parameter", exact:false}]
      // - an array of mixed strings and objects: ["/some-path/:parameter", {path: "/some-path/:parameter", exact:true}]

      if (typeof path === "string") {
        pushRoute(name, strRoute(path), path, "a");
      }

      if (typeof path === "object") {
        if (Array.isArray(path)) {
          path.forEach(item => {
            if (typeof item === "string") {
              pushRoute(name, strRoute(item), item, "b");
            } else if (typeof item === "object") {
              pushRoute(name, objRoute(item), item, "c");
            }
          });
        } else {
          pushRoute(name, objRoute(path), path.path, "d");
        }
      }
    });

    return result;
  }

  /**
   * @description Get the page that matches the given redirect
   * @param {Object} redirect
   * @returns {Object} Returns the found page on success, NULL otherwise
   * @memberof App
   */
  matchRedirectPage(redirect) {
    const match = this.props.pages.find(page =>
      redirect.isPath
        ? redirect.to === page.path || redirect.to === page.key
        : redirect.to === page.key
    );

    if (match) {
      return { from: redirect.from, to: match.path, error: false };
    }

    return { error: redirect };
  }

  /**
   * @description Get the site redirected route paths
   * @returns {array}
   * @memberof App
   */
  getRedirects() {
    // only path-redirects make sense to be rerouted
    // the non path-redirects are meant to be resolved to a valid route/path than rerouting
    const redirectPages = this.props.redirects
      .filter(redirect => redirect.isPath)
      .map(redirect => this.matchRedirectPage(redirect));

    redirectPages
      .filter(redirect => redirect.error)
      .forEach(({ error }) =>
        debug(
          `Invalid redirect definition, the target path not found:\n- from: ${error.from}\n- to: ${error.to}\n- isPath: ${error.isPath}`,
          "error"
        )
      );

    const result = redirectPages
      .filter(redirect => !redirect.error)
      .map((item, index) => {
        this.logRedirectsToConsole(item.from, item.to);

        return <Redirect key={index} from={item.from} to={item.to} />;
      });

    return result;
  }

  /**
   * There is a site!
   * - the site has multiple pages
   *    - a page has ALWAYS a route and might have some heading stuff (like title, meta, scripts, styles, etc)
   *    - a page is the concatenation of one or more templates (right now this app uses only one per page)
   *    - the page's route wraps a Root component that implements the page's templates/items
   *        - a template is the concatenation of one or more template items
   *            - a template item defines the component it wraps and the data (the JSON payload/props) that are feeded to the component
   *              - a component is dumb :o(
   *              - a component must be feed with properties/data to become smart ;-)
   *            - is the job of the template item (or its ancestors) to fetch and feed the component with data/props!
   *
   * Nevertheless:
   *  - in top of what we call `site` there is a Helmet which allows us to customize the html/head/body block of the rendered page
   *    - to load a given page we need a router which maps a given route/path to a page-component
   *      - to automatically scroll to top when a new page is rendered, we have a ScrollToTop specialized component for that
   *        - to store the application state globally we have a Redux storage provider
   */
  render() {
    // TODO: test any page like /something-which-doesnt-exist and see where the 404 goes to!

    const redirect404 = this.props.pathfinder.get({
      name: this.props.httpErrors[404],
      params: {
        invalidPage: this.props.pathfinder.escape(
          window.location.pathname.slice(1)
        )
      }
    });

    return (
      <BrowserRouter>
        <ScrollRestoration />
        <ScrollToTop headless>
          <LayoutItem
            items={HeaderTemplate({
              siteId: this.props.siteId,
              templates: this.props.headerTemplates,
              pageHeader: this.props.header,
              pathfinder: this.props.pathfinder
            })}
            headless
          />

          <Container
            ref={this.pageBodyRef}
            className={PageBodyBS}
            id={PageBodyBS + "-wrapper"}
            fluid
          >
            <Switch>
              {this.routes}
              {this.redirects}
              <Redirect to={redirect404} />
            </Switch>
            {this.props.children}
          </Container>
          <LayoutItem
            items={FooterTemplate({
              siteId: this.props.siteId,
              gdpr: this.props.gdpr,
              widgets: widgetsAsTemplateItems(
                this.props.footer.linkSection.widgets
              ),
              templates: this.props.footerTemplates
            })}
            headless
          />
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

App.propTypes = {
  className: PropTypes.string,
  footer: PropTypes.object,
  footerTemplates: PropTypes.arrayOf(PropTypes.object),
  gdpr: PropTypes.object,
  headerTemplates: PropTypes.arrayOf(PropTypes.object),
  helmet: PropTypes.shape(HelmetProps()),
  homePageDef: PropTypes.object.isRequired,
  httpErrors: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  pathfinder: PropTypes.object.isRequired,
  redirects: PropTypes.arrayOf(PropTypes.object).isRequired,
  siteId: PropTypes.number,
  siteContext: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};

App.mapValueToProps = (value, { store }) => ({
  redirects: value.redirects,
  className: value.className,
  helmet: value.helmet,
  pages: value.pages,
  homePageDef: value.homePageDef,
  httpErrors: value.httpErrors,
  footer: value.footer,
  header: value.header,
  footerTemplates: mapValueToTemplates(value, FOOTER_TEMPLATE_KEY),
  gdpr: value.gdpr,
  headerTemplates: mapValueToTemplates(value, HEADER_TEMPLATE_KEY),
  siteContext: value
});

export default connectHOCs(App, { withSite: true });
