import PureComponent from "@components-core/PureComponent";
import RouteLink from "@components-core/RouteLink";
import SiteLogo from "@components/SiteLogo/SiteLogo";
import { EVENT_MENU_TOGGLED } from "@constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuBarBurgerBS, MenuBarHeaderBS } from "@style-variables";
import { createCustomEvent } from "@utils/dom";
import React from "react";
import { Col, Container, Navbar, Row } from "react-bootstrap";
import { slide as Menu } from "react-burger-menu";
import MenuBarToggle from "./Toggle";

class MenuBarBurger extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { isOpen: false, stack: [props.items], parentStack: [] };

    this.handleBurgerMenuToggle = this.handleBurgerMenuToggle.bind(this);
  }

  componentDidMount() {
    document.addEventListener(EVENT_MENU_TOGGLED, this.handleBurgerMenuToggle);
  }

  componentWillUnmount() {
    document.removeEventListener(
      EVENT_MENU_TOGGLED,
      this.handleBurgerMenuToggle
    );
  }

  handleBurgerMenuToggle(e) {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  renderItem(item, key, style) {
    const hasChildren = item.items && item.items.length; //&& !item.items.some(it => !it.items);
    // const children = hasChildren
    //   ? item.items.map((_item, i) =>
    //     this.renderItem(_item, key + "-" + i, {
    //       fontWeight: 300,
    //       color: "#979797",
    //       paddingLeft: "1rem"
    //     })
    //   )
    //   : null;

    const handleItemClick = e => {
      if (!item.items) {
        if (item.url || item.hasAnchor) {
          this.setState({ isOpen: false });
          document.dispatchEvent(createCustomEvent(EVENT_MENU_TOGGLED));
        }

        return;
      }

      this.setState({
        stack: [...this.state.stack, item.items],
        parentStack: [...this.state.parentStack, { ...item, items: undefined }]
      });
    };

    return (
      <React.Fragment key={key}>
        <li
          style={hasChildren ? { ...style, fontWeight: 600 } : style}
          className={"MenuBurgerListItem"}
        >
          {(item.hasAnchor || !item.items) && item.url ? (
            <RouteLink url={item.url} onClick={handleItemClick}>
              {item.caption}
            </RouteLink>
          ) : (
            <div
              onClick={handleItemClick}
              onKeyUp={handleItemClick}
              role="menuitem"
              tabIndex={0}
            >
              {item.caption}
            </div>
          )}
          {hasChildren ? (
            <FontAwesomeIcon
              icon="chevron-right"
              className="icon"
              onClick={handleItemClick}
            />
          ) : null}
        </li>
        {/* {children} */}
      </React.Fragment>
    );
  }

  renderItems() {
    const index = this.state.stack.length - 1;
    const items = this.state.stack[index];

    const siblingItem = this.state.parentStack.reduce(
      (carry, item) => ({
        caption: [...carry.caption, item.caption],
        url: item.url
      }),
      { caption: [] }
    );

    const handleBackClick = e => {
      this.setState({
        stack: this.state.stack.slice(0, this.state.stack.length - 1),
        parentStack: this.state.parentStack.slice(
          0,
          this.state.parentStack.length - 1
        )
      });
    };

    const hasParent = this.state.stack.length > 1;

    const firstItem = hasParent ? (
      <li className="MenuBurgerListItem" key="back">
        <div
          className="cursor-pointer"
          onClick={handleBackClick}
          onKeyUp={handleBackClick}
          role="menuitem"
          tabIndex={0}
        >
          <FontAwesomeIcon icon="chevron-left" />
          <span> {this.props.backItemTitle}</span>
        </div>
      </li>
    ) : (
      <li key="back" className="MenuBurgerListItem back">
        &nbsp;
      </li>
    );

    const showSiblingItem = hasParent
      ? this.renderItem(
          {
            ...siblingItem,
            caption: this.props.parentItemPrefix
              .replace(
                "%parents%",
                siblingItem.caption.filter(Boolean).toReversed().join(" ")
              )
              .replace("%parent%", siblingItem.caption.slice(-1))
          },
          "siblings",
          { fontWeight: 700 }
        )
      : null;

    const topItems = items.filter(
      ({ position }) => !position || position === MenuBarBurger.POSITION_LEFT
    );
    const bottomItems = items.filter(
      ({ position }) => position === MenuBarBurger.POSITION_RIGHT
    );

    const separator =
      topItems.length && bottomItems.length ? (
        <li className="MenuBurgerListItem separator">&nbsp;</li>
      ) : null;

    return (
      <ul className="MenuBurgerList">
        {firstItem}
        {showSiblingItem}
        {topItems.map((item, i) => this.renderItem(item, i))}
        {separator}
        {bottomItems.map((item, i) => this.renderItem(item, i))}
      </ul>
    );
  }

  renderHeader(mobile = true) {
    return (
      <Container className={MenuBarBurgerBS + "-header"} fluid>
        <Row>
          <Col xs={9}>
            <SiteLogo
              isMobile={mobile}
              className={MenuBarHeaderBS}
              wrapper={Navbar.Brand}
            />
          </Col>
          <Col>
            <MenuBarToggle
              toggled={this.state.isOpen}
              onClick={e =>
                document.dispatchEvent(createCustomEvent(EVENT_MENU_TOGGLED))
              }
              icon={{ icon: "bars" }}
              position="right"
              inline={false}
              className="h-100 justify-content-end align-items-center"
            />
          </Col>
        </Row>
      </Container>
    );
  }

  render() {
    const page = this.state.isOpen ? (
      <div className={MenuBarBurgerBS + "-wrapper"}>
        {this.renderHeader()}
        <Container id="responsive-navbar-nav" className={MenuBarBurgerBS} fluid>
          {this.renderItems()}
        </Container>
      </div>
    ) : null;

    return (
      <>
        <Menu
          customBurgerIcon={false}
          customCrossIcon={false}
          onOpen={() => {
            console.log("is open");
          }}
          onClose={() => {
            console.log("is close");
          }}
          isOpen={this.state.isOpen}
          className={MenuBarBurgerBS}
          width={0}
        />
        {page}
      </>
    );
  }
}

MenuBarBurger.POSITION_LEFT = "left";
MenuBarBurger.POSITION_RIGHT = "right";

export default MenuBarBurger;
