import SizeProps from "@prop-types/SizeProps";
import { ImageCarouselBS } from "@style-variables";
import { shallowDeepCompare } from "@utils/array";
import PreloadHelper from "@utils/preload";
import PropTypes from "prop-types";
import React from "react";
import { Carousel } from "react-bootstrap";
import ImageLoadingType from "../prop-types/ImageLoadingType";
import AbstractCarousel from "./AbstractCarousel";
import Picture from "./Picture";
import { withPlaceholder } from "./Placeholder";
import RouteLink from "./RouteLink";

export default class ImageCarousel extends AbstractCarousel {
  constructor(props) {
    super(props);

    this.helper = new PreloadHelper();
    this.helper.setProps({
      ...props,
      items: this.getCarouselItems(),
      preloadCount: 1,
      prefetchCount: this.props.itemsPerSlide - 1
    });
    this.helper.mountLinks();
  }

  componentDidUpdate(prevProps) {
    if (shallowDeepCompare(prevProps, this.props)) {
      this.helper.setProps(this.props);
      this.helper.mountLinks();
    }
  }

  /**
   * @description Get the component SASS variable name
   * @returns {string}
   * @memberof ImageCarousel
   */
  getComponentClassName() {
    return ImageCarouselBS;
  }

  /**
   * @description Get the suffix that will be appended to the carousel item classname
   * @returns {string}
   * @memberof ImageCarousel
   */
  getItemSuffix() {
    return "item";
  }

  /**
   * @description Get the carousel slide's inner element classname
   * @returns {string}
   * @memberof ImageCarousel
   */
  getItemClass() {
    return this.props.className + " text-center";
  }

  /**
   * @description Get the item's image props
   * @param {Object} item The carousel item
   * @returns {Object} Returns the carousel item's props
   * @memberof ImageCarousel
   */
  getImageProps(item) {
    let imgProps = { title: item.title };

    if (typeof item.img === "string") {
      imgProps.src = item.img;
    }
    if (typeof item.img === "object") {
      imgProps = {
        ...imgProps,
        ...item.img
      };
    }

    return imgProps;
  }

  /**
   * @inheritdoc
   * @returns {JSX}
   * @memberof ImageCarousel
   */
  renderItem(item, index, array) {
    const imgProps = this.getImageProps(item);

    const captionBody = this.props.showCaption
      ? withPlaceholder(this.props.placeholder, item.title)
      : null;

    const caption = captionBody ? (
      <Carousel.Caption className={"caption"}>{captionBody}</Carousel.Caption>
    ) : null;

    const wrapRouteLink = (component, url, title) => (
      <RouteLink to={url} title={title}>
        {component}
      </RouteLink>
    );

    const picture = (
      <Picture
        {...{
          ...imgProps,
          loading:
            item.loading || "lazy" /*index ? this.props.loading : "eager"*/
        }}
        className="mx-auto d-block"
        style={{
          minHeight: this.props.minHeight,
          maxHeight: this.props.maxHeight
        }}
        onClick={item.onClick}
        onLoad={e => {
          this.notifyListener(this.state.index || 0, this.state.direction);
        }}
        placeholder={this.props.placeholder}
        ribbons={this.props.ribbons}
      />
    );

    const image = item.url
      ? wrapRouteLink(picture, item.url, item.title || imgProps.title)
      : picture;

    return (
      <React.Fragment key={index}>
        {image}
        {caption}
      </React.Fragment>
    );
  }
}

ImageCarousel.propTypes = {
  ...AbstractCarousel.propTypes,
  minHeight: SizeProps(),
  maxHeight: SizeProps(),
  prefetch: PropTypes.bool,
  preload: PropTypes.bool,
  ribbons: PropTypes.arrayOf(PropTypes.element),
  loading: ImageLoadingType()
};

ImageCarousel.defaultProps = {
  ...AbstractCarousel.defaultProps,
  maxHeight: null,
  prefetch: true,
  preload: true,
  loading: "lazy"
};
