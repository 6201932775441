import { debug } from "@utils/debug";
import { getExtension } from "@utils/filesystem";
import { SUPPORTED_IMAGE_EXT } from "@utils/image";
import deepmerge from "deepmerge";
import { getMediaQuery } from "./breakpoints";
import {
  CLOUDINARY_ASSETS_ORIGIN,
  getCloudinaryUrl,
  getTimeVersion
} from "./cloudinary";
import { getResponsiveStyle } from "./responsive";
import { hashValue, stringToSlug, toArray } from "./strings";

const IMAGE_SIZE_CACHE = {};

/**
 * @description Helper class that aids in rendering a Cloudinary|normal multi-source lazy-loading picture/image
 * @export
 * @class PictureHelper
 * @see https://www.sitepoint.com/how-to-build-your-own-progressive-image-loader/
 */
export default class PictureHelper {
  // https://en.wikipedia.org/wiki/Data_URI_scheme
  static dataURIPattern = "data:image";
  static dataURIPatternLen = PictureHelper.dataURIPattern.length;

  /**
   *Creates an instance of PictureHelper.
   * @param {Object} props see Picture.propTypes
   * @memberof PictureHelper
   */
  constructor(props) {
    this.setProps = this.setProps.bind(this);

    this.setProps(props);
  }

  /**
   * @description Update the properties
   * @param {Object} props see Picture.propTypes
   * @memberof PictureHelper
   */
  setProps(props) {
    this.props = props;
  }

  encodedAsDataURIScheme(src) {
    return (
      src &&
      src.slice(0, PictureHelper.dataURIPatternLen) ===
        PictureHelper.dataURIPattern
    );
  }

  /**
   * @description Strip the non-Image attributes from the given object
   * @param {Object} item - The object being stripped
   * @returns {Object}
   * @memberof Picture Returns a new object which contains only Image-aware attributes
   */
  stripNonImageAttrs(item) {
    const result = {};

    // this component is a wrapper for multiple components, not all supports the below props
    const nonImageKeys =
      /lazy|sizes|aspect|cloudinary|default|errorImage|hoverImg|removeBackground|errorVideo|version|video|maxWidth|maxHeight|botDisabled|missingImageClassName|titleAs|imgType|padding|autoHeight|autoHover|factory/;
    Object.keys(item).forEach(key => {
      if (!nonImageKeys.test(key)) {
        result[key] = item[key];
      }
    });

    return result;
  }

  /**
   * @description Generates a version string based on the current date/time
   * @param {string} [prefix=""] A version prefix (eg. `v` + 1578040579)
   * @returns {String} Returns a version rounded to current hour (ie. minutes/seconds are zerorized)
   */
  getVersion(prefix = "") {
    if (!this.props.version) {
      return "";
    }

    return getTimeVersion(prefix, this.props.version);
  }

  /**
   * @description Get the image source Cloudinary URL
   * @param {string} src The image source URI
   * @param {string} [width=null] The width for which the image URL should be generated. When null then auto;
   * @returns {String}
   */
  getCloudinaryUrl(src, width = null, format = null) {
    const seoSuffix = this.props.title || this.props.alt;

    return getCloudinaryUrl({
      src,
      cloudinary: this.props.cloudinary,
      version: this.getVersion("v"),
      seoSuffix: seoSuffix ? stringToSlug(seoSuffix.toLowerCase()) : seoSuffix,
      width,
      format,
      maxWidth: this.props.maxWidth,
      maxHeight: this.props.maxHeight,
      padding: this.props.padding,
      extension: this.props.extension,
      removeBackground: this.props.removeBackground
    });
  }

  /**
   * @description Get the image source Cloudinary URL
   * @param {string} src The image source URI
   * @param {string} [width=null] The width for which the image URL should be generated. When null then auto;
   * @returns {String}
   */
  getLocalServerUrl(src, width = null) {
    return src + this.getVersion("?v");
  }

  /**
   * @description Get the image source local server URL
   * @param {string} src The image source URI
   * @param {string} [width=null] The width for which the image URL should be generated. When null then auto;
   * @returns {String}
   */
  getImageUrl(src, width = null, format = null) {
    if (this.encodedAsDataURIScheme(src)) {
      return src;
    }

    if (this.props.cloudinary && !/^https?:\/\//.test(src)) {
      return this.getCloudinaryUrl(src, width, format);
    }

    return this.getLocalServerUrl(src, width);
  }
  /**
   * @description Determine the image size for the given URL
   * @param {String} url The image url
   * @param {Boolean} [cache=true] When true cache the result
   * @returns {Promise} Returns a promise that resolves to the image length (default 0)
   * @memberof PictureHelper
   */
  static getImageSize(url, cache = true) {
    if (!url) {
      return Promise.resolve(0);
    }

    const cacheKey = hashValue(url);
    if (cache && IMAGE_SIZE_CACHE[cacheKey]) {
      return Promise.resolve(IMAGE_SIZE_CACHE[cacheKey]);
    }

    if (url.startsWith(PictureHelper.dataURIPattern)) {
      const base64DataIndex = url.indexOf(";base64,");
      const length = url.slice(base64DataIndex + 8).length;

      if (cache) {
        IMAGE_SIZE_CACHE[cacheKey] = length;
      }

      return Promise.resolve(length);
    }

    const imageURL = new URL(url);

    if (imageURL.origin !== CLOUDINARY_ASSETS_ORIGIN) {
      const extension = getExtension(imageURL.pathname, false);

      if (extension && !SUPPORTED_IMAGE_EXT.includes(extension)) {
        debug(`Invalid image URL : ${url}`, "warn");
        return Promise.resolve(0);
      }
    }

    return fetch(url, { method: "HEAD" })
      .then(response => {
        const contentLength = response.headers.get("Content-Length");

        if (cache) {
          IMAGE_SIZE_CACHE[cacheKey] = contentLength;
        }

        return contentLength;
      })
      .catch(error => {
        debug(`Error fetching HEAD of: ${url}`, "error");
        debug(error, "error");
        return Promise.resolve(0);
      });
  }

  /**
   * @description Get the default image width
   * @returns {number|null} Returns the default image size in pixels in case of unique size regardless media, NULL otherwise
   */
  getDefaultImageSize() {
    const defaultSize = {};

    const points = ["width", "minWidth", "maxWidth", "maxHeight"];

    if (this.props.imgSize) {
      defaultSize.any = points
        .map(point => this.props.imgSize[point])
        .filter(Boolean);
    }

    // in case props.sizes is undefined, we try our ad-hoc defaultSize if possible
    const obj = getResponsiveStyle(
      this.props.sizes,
      this.props.aspect || 1,
      defaultSize
    );

    if (obj) {
      return points.reduce(
        (carry, point) => Math.max(carry, obj[point] || 0),
        0
      );
    }

    return null;
  }

  /**
   * @description Render the picture inner image element
   * @param string src The image source URI
   * @param {Object} [props={}] Extra properties to pass to the Image component
   * @returns {JSX}
   */
  getDefaultImage(src, props = {}) {
    let item;

    if (this.props.items) {
      item = this.props.items.find(item => item.default);
      if (this.props.items.length && !item) {
        item = this.props.items[0];
      }
    } else {
      item = this.props;
    }

    item = deepmerge(item, props);

    const itemProps = {};

    Object.keys(item).forEach(key => {
      if ("imgSize" === key) {
        itemProps.style = item[key];
      } else {
        itemProps[key] =
          "object" === typeof item[key] && itemProps[key]
            ? Object.assign({}, itemProps[key], item[key])
            : item[key];
      }
    });

    itemProps.src = this.getImageUrl(src, this.getDefaultImageSize());
    itemProps.alt = item.alt ? item.alt : item.title;

    // seems that the CSS min() does not work
    // if (itemProps.style) {
    //   if (itemProps.style.minWidth) {
    //     itemProps.style.minWidth = `min(${itemProps.style.minWidth},100%)`;
    //   }
    //   if (itemProps.style.minHeight) {
    //     itemProps.style.minHeight = `min(${itemProps.style.minHeight},100%)`;
    //   }
    // }

    //itemProps.height = itemProps.height || "auto";

    return {
      ...this.stripNonImageAttrs(itemProps),
      thumbnail: this.props.thumbnail,
      loading:
        this.props.loading || "lazy" /*props.lazyLoading ? "lazy" : "eager"*/
    };
  }

  /**
   * @description Render the image sources for the picture
   * @param string src The image source URI
   * @returns {JSX}
   */
  renderSources(src) {
    let items = this.props.items ? this.props.items : [];
    const sizes = this.props.sizes || null;

    if (sizes) {
      Object.keys(sizes).forEach(point => {
        // a sizes[point] can be an array which normally should contain the min/max-width values
        toArray(sizes[point])
          .filter(Boolean)
          .forEach(width => {
            const url = this.getImageUrl(src, width);
            const size = isFinite(width) ? width + "px" : width;
            const media = getMediaQuery(point);

            let item = items.find(item => item.srcSet === url);

            if (item) {
              item.sizes.push(size);
              item.media.push(media);
            } else {
              items.push({
                srcSet: url,
                sizes: [isFinite(width) ? width + "px" : width],
                media: [media]
              });
            }
          });
      });
    }

    if (items.length) {
      return items
        .filter(item => !item.default)
        .map(item => {
          // when less than 2 sizes then no need for multiple sources
          if (items.length > 1) {
            return {
              srcSet: item.srcSet,
              sizes: item.sizes.join(","),
              media: item.media.join(",")
            };
          }

          return null;
        })
        .filter(Boolean);
    }

    return null;
  }

  /**
   * @description Render the picture as a multi-source image
   * @param string src The image source URI. Default to `src` property.
   * @returns {JSX}
   */
  getProps(src) {
    // note: in case the browser cache is disabled the visible slide image is fetched again
    // a better version would manage its own cached Image collection and assign a pre-loaded Image to the visible slide

    const props = {};

    const sources = this.renderSources(src || this.props.src);

    if (/*!(sources && sources.length) &&*/ this.props.sizes) {
      const width = this.props.sizes.any;

      if (width) {
        const size = isFinite(width) ? width + "px" : width;

        props.width = size;
      }

      const sizes = Object.values(this.props.sizes).filter(Boolean);

      if (sizes.length) {
        const maxWidth = Math.max(...sizes);
        //const minWidth = Math.min(...sizes);

        props.style = props.style || {};

        // if (minWidth !== maxWidth)
        {
          props.style = {
            ...props.style,
            maxWidth: maxWidth + "px",
            //minWidth: minWidth + "px",
            minWidth: "100%",

            width: "auto"
          };
        }

        // const aspect = this.props.aspect || 1;
        //props.style.minHeight = aspect * minWidth + "px";
      }
    }

    const defaultImage = this.getDefaultImage(src, props);

    if ((defaultImage.style || {}).maxWidth && !defaultImage.style.width) {
      defaultImage.style.width = "100%";
    }
    if ((defaultImage.style || {}).maxHeight && !defaultImage.style.height) {
      defaultImage.style.height = "100%";
    }

    const result = { sources, defaultImage };

    return result;
  }
}

const getImageSize = PictureHelper.getImageSize;

export { getImageSize };
