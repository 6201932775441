import { connectHOCs } from "@components-utils";
import ProductPriceProps from "@prop-types/ProductPriceProps";
import TitleTextProps from "@prop-types/TitleTextProps";
import { CheckoutSubtotalBS } from "@style-variables";
import {
  formatCurrency,
  formatNumber,
  getComponentClassName
} from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";

const CheckoutSubtotal = ({
  isCartEmpty,
  className,
  cartValue,
  amount,
  currency,
  i18n,
  vat,
  discount,
  discountedPrice,
  shipment,
  subtotal,
  otherOptions
}) => {
  if (isCartEmpty) {
    return null;
  }

  const _i18n = subtotal.i18n;

  const _formatCurrency = value =>
    formatCurrency(
      formatNumber(value, currency.decimal, currency.thousand),
      currency.prefix,
      currency.suffix
    );

  const CheckoutSubtotalItem = ({ key, title, value, hideNullValue }) =>
    value || !hideNullValue ? (
      <div className={getComponentClassName(CheckoutSubtotalBS, key)}>
        <span>{title}</span>
        <span> {_formatCurrency(value)}</span>
      </div>
    ) : null;

  return (
    <div className={getComponentClassName(CheckoutSubtotalBS, null, className)}>
      <div>
        <CheckoutSubtotalItem
          key="gross"
          title={_i18n.cartValue}
          value={cartValue}
        />
        <CheckoutSubtotalItem
          key="shipping"
          title={_i18n.shippingValue}
          value={shipment}
        />
        <CheckoutSubtotalItem
          key="assembly"
          title={_i18n.assemblyValue}
          value={otherOptions.assembly}
          hideNullValue
        />
        <CheckoutSubtotalItem
          key="install"
          title={_i18n.installValue}
          value={otherOptions.install}
          hideNullValue
        />
        <CheckoutSubtotalItem
          key="ordering"
          title={_i18n.orderingValue}
          value={otherOptions.ordering}
          hideNullValue
        />
        <CheckoutSubtotalItem
          key="extra"
          title={_i18n.extraValue}
          value={otherOptions.extra}
          hideNullValue
        />
        <CheckoutSubtotalItem
          key="discount"
          title={_i18n.cartDiscount}
          value={discount + discountedPrice}
        />
        <CheckoutSubtotalItem key="vat" title={_i18n.vatValue} value={vat} />
        <CheckoutSubtotalItem
          key="total-discounted"
          title={_i18n.orderValue}
          value={amount}
        />
      </div>
    </div>
  );
};

CheckoutSubtotal.propTypes = {
  ...TitleTextProps,
  ...ProductPriceProps,
  className: PropTypes.string
};

CheckoutSubtotal.mapStateToProps = (state, ownProps) => {
  const gross = state.calculatorResult.gross || {};
  const vat = state.calculatorResult.vat || {};

  const discountedPrice = (state.cart.items || []).reduce(
    (carry, item) =>
      carry +
      (item.product.oldPrice
        ? (item.product.oldPrice - item.product.newPrice) * item.quantity
        : 0),
    0
  );

  return {
    isCartEmpty: !state.cart.items.length,
    cartValue: gross.cartValue || 0,
    amount: gross.orderValue || 0,
    vat: vat.orderValue || 0,
    discountedPrice,
    discount: gross.couponsValue || 0,
    shipment: gross.shipmentValue || 0,
    otherOptions: gross.otherOptions
  };
};

CheckoutSubtotal.mapDispatchToProps = null;

CheckoutSubtotal.mapValueToProps = value => ({
  currency: value.currency,
  subtotal: value.checkout.subtotal
});

export default connectHOCs(CheckoutSubtotal, {
  withSite: true,
  withConnect: true
});
