import {
  CHECKOUT_FETCH_PAYMENT_METHODS_FAILURE,
  CHECKOUT_FETCH_PAYMENT_METHODS_REQUEST,
  CHECKOUT_FETCH_PAYMENT_METHODS_SUCCESS,
  CHECKOUT_SET_PAYMENT_METHOD
} from "../actionTypes";

const checkoutPayment = (state = {}, action) => {
  const { paymentMethod } = action;

  switch (action.type) {
    case CHECKOUT_SET_PAYMENT_METHOD:
      return { ...state, paymentMethod };
    default:
      return state;
  }
};

const checkoutPaymentMethodsResult = (state, action) => {
  const newState = {
    methods: null,
    cart: null,
    allowedMethods: null,
    error: null,
    isFetching: false
  };

  switch (action.type) {
    case CHECKOUT_FETCH_PAYMENT_METHODS_REQUEST:
      return { ...newState, isFetching: true };
    case CHECKOUT_FETCH_PAYMENT_METHODS_SUCCESS:
      return {
        ...newState,
        methods: action.methods,
        allowedMethods: action.allowedMethods,
        isFetching: false
      };
    case CHECKOUT_FETCH_PAYMENT_METHODS_FAILURE:
      return { ...newState, error: action.error.message };
    default:
      return state || newState;
  }
};

export default {
  checkoutPayment,
  checkoutPaymentMethodsResult
};
