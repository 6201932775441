import ExternalLink from "@components-core/ExternalLink";
import Picture from "@components-core/Picture";
import ReadMore from "@components-core/ReadMore";
import RouteLink from "@components-core/RouteLink";
import { EscapedRouteLinkBS, ExternalLinkBS } from "@style-variables";
import React from "react";
import { Badge, Button } from "react-bootstrap";
import { createPortal as reactCreatePortal } from "react-dom";
import { debug } from "./debug";
import { BADROUTE_ON_NOTFOUND, BOOL_ON_NOTFOUND, prefixRoute } from "./route";
import { camelCase, decodeHtmlEntities } from "./strings";
import { isExternalUrl } from "./url";

const domParser = new DOMParser();

const startMask = "@#";
const endMask = "#@";
const substitutions = [];

/**
 * @description Mask the given value
 * @param {*} value The value to mask
 * @returns {String} The masked value
 */
const maskValue = value => {
  substitutions.push(value);
  return startMask + (substitutions.length - 1) + endMask;
};

/**
 * @description Replaces the HTML tag with its equivalent React value's mask
 * @param {String} str The input string
 * @param {Object} tag The tag to replace, where tag prototype is { tagName, replacer }
 * @returns {String} Returns the input string by replacing the HTML tagName with the replacer masked value
 */
const replaceTag = (str, tag) => {
  if (!str) {
    return str;
  }

  const { tagName, replacer } = tag;

  const start = str.indexOf(`<${tagName}`);
  const endTag = `</${tagName}>`;

  if (start !== -1) {
    const rest = str.slice(start);
    const end = rest.indexOf(endTag);
    if (end !== -1) {
      return (
        str.slice(0, start) +
        maskValue(replacer(rest.slice(0, end).concat(endTag))) +
        replaceTag(rest.slice(end + endTag.length), tag)
      );
    }
  }

  return str;
};

const newlineToBlock = str => {
  if (str.indexOf("\n") !== -1) {
    return str
      .split("\n")
      .map(
        (item, key) => `<div key="${key}" className="d-block">${item}</div>`
      );
  }

  return str;
};

/**
 * @description Escape one or more tags
 * @param {String} str The input string
 * @param {Object} tags One or more tags with the prototype {tagName:replacer}
 * @param {bool} convertNewline When true convert the new line to a block element
 * @returns {String|JSX} Returns the equivalent escaped React element
 */
const escapeTags = (str, tags, convertNewline) => {
  if (!str) {
    return str;
  }

  let s = str;
  Object.keys(tags).forEach(tagName => {
    s = replaceTag(s, { tagName, replacer: tags[tagName] });
  });

  if (!substitutions.length) {
    return convertNewline ? newlineToBlock(str) : str;
  }

  const children = [];

  let start = s.indexOf(startMask);
  while (start !== -1) {
    const rest = s.slice(start);
    const end = rest.indexOf(endMask);
    if (end !== -1) {
      if (convertNewline) {
        children.push(...newlineToBlock(s.slice(0, start)));
      } else {
        children.push(s.slice(0, start));
      }
      const i = +rest.slice(startMask.length, end);
      children.push(substitutions[i]);
      s = rest.slice(end + endMask.length);
    } else {
      break;
    }

    start = s.indexOf(startMask);
  }

  if (convertNewline) {
    children.push(...newlineToBlock(s));
  } else {
    children.push(s);
  }

  const result = (
    <React.Fragment>
      {children.map((item, key) => {
        if ("string" === typeof item) {
          return <span key={key} dangerouslySetInnerHTML={{ __html: item }} />;
        } else {
          if (React.isValidElement(item)) {
            return React.createElement(
              item.type,
              { ...item.props, key },
              item.props.children
            );
          }

          debug(
            `Cannot escape the "${Object.keys(tags).join(
              ", "
            )}" tags of the "${str}" string.`,
            "error"
          );

          return null;
        }
      })}
    </React.Fragment>
  );

  return result;
};

/**
 * @description Escape the native HTML tags by converting them to React elements
 * @param {String} str
 * @param {Object} [pathfinder=null] When given then the route pathfinder
 * @param {bool} [convertNewline=false] When true convert the new line to a block element
 * @param {bool} [ignoreRouteError=true] When true ignore route not found exceptions
 * @returns {JSX} Returns the escaped input string
 */
const escapeReact = (
  str,
  pathfinder = null,
  convertNewline = false,
  ignoreRouteError = true
) => {
  if (!str || !/<[a-z]+[a-z0-9]*(\s+.*)?\/?>/i.test(str)) {
    return str;
  }

  let html = domParser.parseFromString(
    convertNewline ? str.replace(/\n/g, "<br/>") : str,
    "text/html"
  );

  const nameToPropName = name => {
    switch (name) {
      case "class":
        return "className";
      case "itemprop":
        return "itemProp";
      case "itemscope":
        return "itemScope";
      case "itemtype":
        return "itemType";
      default:
        return name;
    }
  };

  const valueToPropValue = attr => {
    switch (attr.name) {
      case "style":
        return (attr.value || "").split(";").reduce((carry, attr) => {
          const [n, v] = (attr || "").split(":").map(p => p.trim());
          return Object.assign(carry, { [camelCase(n)]: v });
        }, {});
      default:
        return attr.value;
    }
  };

  const extractProps = attrs =>
    Array.from(attrs).reduce((carry, attr) => {
      const key = nameToPropName(attr.name);
      if (key) {
        return Object.assign(carry, {
          [key]: valueToPropValue(attr)
        });
      }

      return carry;
    }, {});

  const renderAnchor = (props, children) => {
    const extern = isExternalUrl(props.href) || "_blank" === props.target;

    const className = [
      EscapedRouteLinkBS,
      extern ? ExternalLinkBS : RouteLink.defaultProps.className,
      props.className
    ]
      .filter(Boolean)
      .join(" ");

    let href = props.href
      ? (extern || !pathfinder || -1 !== props.href.indexOf("/")
          ? props.href
          : pathfinder.get(
              props.href,
              null,
              ignoreRouteError ? BOOL_ON_NOTFOUND : BADROUTE_ON_NOTFOUND
            )) || ""
      : "";

    href = href.startsWith("/") ? prefixRoute(href) : href;

    if (extern) {
      return (
        <ExternalLink {...{ ...props, className, href }}>
          {children || props.href}
        </ExternalLink>
      );
    }

    return (
      <RouteLink {...{ ...props, className, href }}>
        {children || props.href}
      </RouteLink>
    );
  };

  const tagToJSX = (tagName, props, children) => {
    switch (tagName) {
      case "readmore":
        return <ReadMore {...props}>{children}</ReadMore>;
      case "a":
        return renderAnchor(props, children);
      case "br":
      case "hr":
        return React.createElement(tagName, props);
      case "img":
        return <Picture {...props} />;
      case "button":
        return <Button {...props}>{children}</Button>;
      default:
        if (/^[a-z][a-z0-9]*$/i.test(tagName)) {
          return React.createElement(tagName, props, children);
        }

        return [tagName, ...children].filter(Boolean);
    }
  };

  return Array.from(html.body.childNodes).map((el, i) => {
    if (el.tagName) {
      const props = Object.assign({ key: i }, extractProps(el.attributes));

      const children = escapeReact(
        decodeHtmlEntities(el.innerHTML), // avoid escaping already encoded chars (eg. `&`)
        pathfinder,
        convertNewline,
        ignoreRouteError
      );

      return tagToJSX(el.tagName.toLowerCase(), props, children);
    }

    return <React.Fragment key={i}>{el.nodeValue}</React.Fragment>;
  });
};

/**
 * @description Replaces one or more placeholders with a given type of React component which may have a given list of props
 * @param {String} text The input text
 * @param {Object} list A list of {%placeholder% : function|{type,props} }
 * @returns {String|JSX} Returns the input text converted to React component where the placeholders were replaced with their respective components
 * @example str="Lorem ipsum dolor sit %some-placeholder%, consectetur adipiscing elit";
 * jsx=stringToJSX(str, {"some-placeholder":{type: Badge, props: {variant:"danger", children: "amet"}}});
 * console.log(jsx); // Lorem ipsum dolor sit <Badge className="danger">amet</Badge>, consectetur adipiscing elit
 *
 */
const stringToJSX = (text, list) => {
  if (!text) {
    return text;
  }

  // build up the escaped tags list and their respective resolvers
  const tags = Object.keys(list).reduce((carry, placeholder, i) => {
    const def = list[placeholder];

    if ("function" === typeof def) {
      return Object.assign(carry, {
        [`tag${i}`]: def
      });
    }

    const props = def.props || {};

    return Object.assign(carry, {
      [`tag${i}`]: str => ({
        type: def.type,
        props: { ...props, children: undefined },
        children: props.children
      })
    });
  }, {});

  /**
   * @description Replaces the given placeholders with their respective tag names in the given input text
   * @param {String} str The input string
   * @param {Object} list A {%placeholder% : tagName} list of placeholders to replace with the HTML <tagName/>
   * @returns {String} Returns the input string with the placeholders replace with HTML tag names
   */
  const maskPlaceholders = (str, list) =>
    Object.keys(list).reduce((carry, placeholder) => {
      const re = new RegExp(`%${placeholder}%`, "g");
      const tag = list[placeholder];
      return carry.replace(re, `<${tag}></${tag}>`);
    }, str);

  // mask the placeholders with a volatile tagName
  const maskedStr = Object.keys(list).reduce((carry, placeholder, i) => {
    return maskPlaceholders(carry, { [placeholder]: `tag${i}` });
  }, text);

  // escape the masked tags with theirs tags resolves
  return escapeTags(maskedStr, tags);
};

/**
 * @description Replaces the placeholder with a Badge having the given children value
 * @param {String} text The input text
 * @param {String} placeholder The replaced placeholder
 * @param {Object} props The Badge properties, including children value
 * @returns {String|JSX} Returns the input text converted to React component where the placeholders were replaced with their respective components
 */
const badgify = (text, placeholder, props) =>
  stringToJSX(text, {
    [placeholder]: { type: Badge, props }
  });

/**
 * @description Render children into DOM node container that exists outside the  DOM hierarchy of the parent component.
 * @param {ReactNode} children The children to render
 * @param {Element} container The DOM element
 * @param {String} key The component key
 * @returns {ReactPortal}
 */
const createPortal = (children, container, key) =>
  reactCreatePortal(children, container, key);

/**
 * @description A replacement for the React's equivalent which returns the input string in case it't not a HTML string
 * @param {String} str The input string
 * @param {String} [Tag="div"] The HTML tag that wraps the input string
 * @param {Object} [attrs={}] Optional attributes passed to the resulting HTML tag
 * @returns {String|JSX} Returs the a HTML element in case of a HTML input, the input string otherwise.
 * @see https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
 * @see https://html.spec.whatwg.org/multipage/syntax.html#start-tags
 */
const dangerouslySetInnerHTML = (str, Tag = "div", attrs = {}) =>
  -1 === /<([a-zA-Z]+)(\s*|>).*(>|\/\1>)/.test(str) ? (
    str
  ) : (
    <Tag dangerouslySetInnerHTML={{ __html: str }} {...attrs} />
  );

export {
  badgify,
  createPortal,
  dangerouslySetInnerHTML,
  escapeReact,
  escapeTags,
  stringToJSX
};
