import { dateToYmd } from "./date";
import { fileHasExtension, getExtension, stripExtension } from "./filesystem";
import {
  actualDeviceDPR,
  isVideo as isVideoURI,
  SUPPORTED_IMAGE_EXT,
  SUPPORTED_VIDEO_EXT
} from "./image";
import { stringToSlug } from "./strings";

// the Cloudinary assets delivery server location origin
const CLOUDINARY_ASSETS_ORIGIN = "https://res.cloudinary.com";

/**
 * @description Format the date to YMD format
 * @param {Date} date The input date. When not given then default to the current date.
 * @param {string} [separator="-"] The format date separator
 * @returns {string}
 */

/**
 * @description Generates a version string based on the current date/time
 * @param {string} [prefix=""] A version prefix (eg. `v` + 1578040579)
 * @param {Boolean|number} [version=0] When true assumes the current date/time with min/sec stripped, otherwise a date based on the given timestamp (eg. 1578040579).
 * @returns {String} Returns a version rounded to current hour (ie. minutes/seconds are zerorized)
 */
function getTimeVersion(prefix = "", version = 0) {
  if (version && Number.isFinite(version) && version > 1577836800) {
    return prefix.concat(version);
  }

  // the version is rounded to the current hour such that the cache gets invalidated in max 1h
  // ie. s = YYYY-MM-DDTHH:00:00.000Z
  const d = true === version ? new Date() : new Date(version * 1000);

  const s =
    dateToYmd(d) +
    "T" +
    d.getHours().toString().padStart(2, "0") +
    ":00:00.000Z";

  return prefix.concat((+new Date(s) / 1e3).toFixed(0));
}

/**
 * @description Get the Cloudinary account URL
 * @param {String} imagestore The Cloudinary path to append to result
 * @param {number} siteId The Cloudinary site Id to append to result
 * @param {String} [path=null] The path within the site folder
 * @returns {String}
 */
function getCloudinaryConsoleUrl(imagestore, siteId, path = null) {
  const folders = [imagestore, siteId, path]
    .filter(Boolean)
    .filter(s => s.replace(/^[/]?(.*?)[/]?$/g, "$1"))
    .join("/");

  return [
    `https://cloudinary.com/console/media_library/folders`,
    encodeURIComponent(folders)
  ]
    .filter(Boolean)
    .join("/");
}

/**
 * @description Get the image source Cloudinary URL
 * @param {Object} options The options based on which the URL is generated
 * @returns {String}
 * @memberof Picture
 */
function getCloudinaryUrl(options) {
  const {
    src,
    cloudinary,
    version,
    seoSuffix,
    width,
    format,
    maxWidth,
    maxHeight,
    padding,
    extension,
    // see prohibited raw extensions: https://support.cloudinary.com/hc/en-us/articles/204292392-Why-does-Cloudinary-reject-the-files-I-m-uploading-
    raw,
    removeBackground
  } = options;

  if (!src) {
    return "";
  }

  const ext = format ? "." + format : getExtension(src);

  const isSVG = !format && fileHasExtension(src, "svg");
  const isVideo =
    (extension && SUPPORTED_VIDEO_EXT.includes(extension.toLowerCase())) ||
    isVideoURI(src);
  const isRaw =
    raw ||
    !(
      isVideo ||
      isSVG ||
      !extension ||
      SUPPORTED_IMAGE_EXT.includes(extension.toLowerCase())
    );

  // https://cloudinary.com/documentation/transformation_reference#fl_flag
  // note: fl_keep_iptc doesn't work with q_auto
  const imageFlags = [];

  // it won't do much for alreay optimized images
  // if (isSVG) {
  //   imageFlags.push("fl_sanitize");
  // }

  //console.log(options);

  // https://cloudinary.com/documentation/image_delivery_options
  let urlParams = [];

  if (!isRaw) {
    urlParams = [
      removeBackground ? "e_bgremoval" : "",
      "f_auto" + (isVideo && !ext ? ":image" : ""),
      "q_auto",
      "dpr_" + actualDeviceDPR("auto")
    ].filter(
      k =>
        (!(isSVG || format) || !k.startsWith("f_auto")) &&
        (!isVideo || !k.startsWith("dpr_"))
    );

    if (isVideo) {
      urlParams.push("c_limit");
    } else {
      if (imageFlags.length) {
        urlParams.push(imageFlags.join("."));
      }

      if (padding) {
        urlParams.push("c_pad", "b_white");
      } else {
        urlParams.push("c_fit");
      }
    }

    if (!isSVG) {
      if (width) {
        if (isFinite(width)) {
          urlParams.push("w_" + width);
          urlParams.push("h_" + width);
        } else if (!(maxWidth || maxHeight)) {
          urlParams.push("w_auto");
        }
      }

      if (maxWidth || maxHeight) {
        // remove the redundant width/height params FIRST!
        urlParams.forEach((param, i) => {
          if (/^(w|h)_\d+$/.test(param)) {
            delete urlParams[i];
          }
        });

        // this seems not working with this transformation type
        delete urlParams[
          urlParams.filter(Boolean).findIndex(i => i.startsWith("f_auto"))
        ];

        if (!isVideo) {
          let condParam;
          if (maxWidth && !maxHeight) {
            condParam = `iw_gt_${maxWidth},w_${maxWidth}`;
          } else if (!maxWidth && maxHeight) {
            condParam = `ih_gt_${maxHeight},h_${maxHeight}`;
          } else {
            condParam = `iw_gt_${maxWidth}_or_ih_gt_${maxHeight},w_${maxWidth},h_${maxHeight}`;
          }
          urlParams.unshift(`if_${condParam},c_fit/`);
        }
      }

      if (isVideo) {
        urlParams.push("vc_auto");

        // if (ext) {
        //   urlParams.push("sp_auto");
        // }
      }
    }
  } else {
    //https://support.cloudinary.com/hc/en-us/articles/202521252-How-can-I-deliver-an-image-as-an-attachment-
    urlParams.push(
      "fl_attachment" +
        (seoSuffix ? ":" + stringToSlug(stripExtension(seoSuffix)) : "")
    );
  }

  const parts = [CLOUDINARY_ASSETS_ORIGIN, cloudinary.cloudName];

  // https://cloudinary.com/blog/how_to_dynamically_create_seo_friendly_urls_for_your_site_s_images#dynamic_seo_suffixes
  if (seoSuffix && !isRaw) {
    parts.push(isVideo ? "videos" : "images");
  } else {
    parts.push(
      isVideo ? "video/upload" : isRaw ? "raw/upload" : "image/upload"
    );
  }

  if (urlParams.filter(Boolean).length) {
    parts.push(urlParams.filter(Boolean).join(",").replace(/\/,/g, "/"));
  }

  if (version) {
    if ("string" === typeof version) {
      parts.push(version);
    } else {
      parts.push(getTimeVersion("v", true));
    }
  }

  parts.push(cloudinary.path);

  parts.push(stripExtension(src));

  if (seoSuffix && !isRaw) {
    parts.push(stringToSlug(seoSuffix));
  }

  const url = parts
    .map(item => item.replace(/^\/?(.+?)\/?$/g, "$1")) //strip the first slash
    .join("/");

  const result = url.concat(ext);

  return result;
}

export {
  CLOUDINARY_ASSETS_ORIGIN,
  getTimeVersion,
  getCloudinaryUrl,
  getCloudinaryConsoleUrl
};
