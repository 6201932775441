import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SearchableDropdownBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { FormControl, InputGroup } from "react-bootstrap";

/**
 * @description A input editor component with a searchable dropdown menu list support
 */
const DropdownToggle = React.forwardRef((props, ref) => {
  return (
    <InputGroup
      className={getComponentClassName(SearchableDropdownBS, "editor")}
      hasValidation
    >
      {/* the input editor */}
      <FormControl
        ref={ref}
        readOnly={props.readOnly || props.disabled}
        disabled={props.disabled}
        type="search"
        size={props.size}
        placeholder={props.placeholder}
        value={props.value}
        isInvalid={props.isInvalid}
        isValid={props.isValid}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        onKeyUp={props.onKeyUp}
        onFocus={props.onFocus}
        className={getComponentClassName(SearchableDropdownBS, "input")}
        required={props.required}
        autoComplete={props.autoComplete}
        pattern={props.pattern}
        tabIndex={0}
      />
      {/* the toggle button */}
      <InputGroup.Append
        style={{ cursor: props.disabled ? "not-allowed" : "pointer" }}
        className={getComponentClassName(SearchableDropdownBS, "toggle")}
        onClick={
          props.disabled
            ? null
            : e => {
                e.preventDefault();

                if ("function" === typeof props.onClick) {
                  props.onClick(e);
                }

                if ("function" === typeof props.onToggle) {
                  props.onToggle(e);
                }
              }
        }
      >
        <InputGroup.Text>
          <FontAwesomeIcon
            icon="chevron-down"
            className={props.disabled ? "text-muted" : undefined}
          />
        </InputGroup.Text>
      </InputGroup.Append>
      {/* the input error feedback */}
      <FormControl.Feedback type="invalid">
        {props.feedback}
      </FormControl.Feedback>
    </InputGroup>
  );
});

DropdownToggle.propTypes = {
  size: PropTypes.oneOf(["sm", "lg"]),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  onClick: PropTypes.func,
  onToggle: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onFocus: PropTypes.func,
  feedback: PropTypes.string,
  required: PropTypes.bool,
  pattern: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(RegExp),
    PropTypes.array
  ]),
  autoComplete: PropTypes.string
};

export default DropdownToggle;
