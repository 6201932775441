import React from "react";
import { Form } from "react-bootstrap";

const ProductVariant = props => {
  if (!props.items.length) {
    return null;
  }

  return (
    <Form.Group
      controlId="product-variant"
      className={[props.className, "form-group variant"]}
    >
      <Form.Label>{props.title}</Form.Label>
      <Form.Control
        as="select"
        required
        onChange={e => {
          const el = e.currentTarget;
          const productId = el.value;
          const searchKey = el.options[el.selectedIndex].dataset.searchkey;

          if (typeof props.onVariant === "function") {
            props.onVariant(productId, searchKey);
          }
        }}
      >
        <option hidden>{props.i18n.CHOOSE_VARIANT}</option>
        {props.items.map((item, i) => (
          <option value={item.id} key={item.id} data-searchKey={item.searchKey}>
            {item.value}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
};

export default ProductVariant;
