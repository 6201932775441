import { connectHOCs } from "@components-utils";
import {
  STOCK_STATUS_AVAILABLE,
  STOCK_STATUS_LIMITED,
  STOCK_STATUS_OUT
} from "@constants";
import { ProductStockStatusBS } from "@style-variables";
import PropTypes from "prop-types";
import React from "react";

const ProductStockStatus = props => {
  const i18n = props.i18n.components.ProductAvailability;

  // see Graphql ProductStockStatus enum
  let statusClass = null;
  let statusText = null;

  if (
    !props.inStock ||
    props.balance === 0 ||
    STOCK_STATUS_OUT === props.status
  ) {
    statusClass = "out-of-stock";
    statusText = i18n.LABEL_AVAILABILITY_OUT_OF_STOCK;
  } else if (
    STOCK_STATUS_AVAILABLE === props.status ||
    (STOCK_STATUS_LIMITED === props.status && props.balance > 0)
  ) {
    statusClass = "available";
    statusText = i18n.LABEL_AVAILABILITY_IN_STOCK;
  } else if (STOCK_STATUS_LIMITED === props.status) {
    // this case should not happen!
    statusClass = "limited";
    statusText = STOCK_STATUS_LIMITED;
  }

  return (
    <span
      className={[ProductStockStatusBS, statusClass].filter(Boolean).join(" ")}
    >
      {statusText}
    </span>
  );
};

ProductStockStatus.propTypes = {
  status: PropTypes.oneOf([
    STOCK_STATUS_AVAILABLE,
    STOCK_STATUS_LIMITED,
    STOCK_STATUS_OUT
  ]),
  balance: PropTypes.number,
  inStock: PropTypes.bool,
  i18n: PropTypes.object
};

export default connectHOCs(ProductStockStatus, { withSite: true });
