import PropTypes from "prop-types";
import React from "react";
import { Carousel } from "react-bootstrap";
//import { isMobileDevice } from "@utils/breakpoints";
import { getComponentClassName } from "@utils/strings";
//import withMagnifier from "../ImageMagnifier/Container";
import ImageProps from "@prop-types/ImageProps";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import PureComponent from "./PureComponent";

export default class AbstractCarousel extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      index: props.activeIndex,
      direction: null,
      interval: props.intervalDelay ? null : props.interval
    };

    this.intervalDelayTimer = null;

    this.colCount = 12; //max Bootstrap responsive columns count

    this.startX = 0;
    this.endX = 0;
    this.threshold = 50; // Minimum drag distance to trigger slide change

    this.listeners = {};

    this.ref = React.createRef();
    this.itemRefs = props.items.map(() => React.createRef());

    this.registerListener = this.registerListener.bind(this);
    this.notifyListener = this.notifyListener.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.getComponentClassName = this.getComponentClassName.bind(this);
    this.getCarouselProps = this.getCarouselProps.bind(this);
    this.getItemSuffix = this.getItemSuffix.bind(this);
    this.getItemClass = this.getItemClass.bind(this);
    this.getCarouselItems = this.getCarouselItems.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.renderCarouselItem = this.renderCarouselItem.bind(this);

    this.CarouselWrapper = /*props.magnifier && !isMobileDevice()
        ? withMagnifier(Carousel, { registerListener: this.registerListener })
        :*/ Carousel;

    if (props.onSelect) {
      this.registerListener({ onSelect: props.onSelect });
    }
  }

  componentDidMount() {
    if (this.props.intervalDelay && null !== this.props.interval) {
      this.intervalDelayTimer = setTimeout(
        () => this.setState({ interval: this.props.interval }),
        this.props.intervalDelay
      );
    }
  }

  componentWillUnmount() {
    if (this.intervalDelayTimer) {
      clearTimeout(this.intervalDelayTimer);
    }
  }

  /**
   * @description Handle the carousel selector
   * @param {*} selectedIndex The selected index
   * @param {*} direction The selected direction (prev|next)
   * @memberof AbstractCarousel
   */
  handleSelect(selectedIndex, direction) {
    const newState = { index: selectedIndex, direction };

    this.notifyListener(selectedIndex, direction);

    this.setState({
      ...this.state,
      ...newState
    });
  }

  /**
   * @description Trigger the image change listeners on slide change
   * @param {number} selectedIndex - The new selected index
   * @param {string} direction - One of `prev`|`next`
   * @memberof AbstractCarousel
   */
  notifyListener(selectedIndex, direction) {
    const listeners = Object.keys(this.listeners);

    if (listeners.length) {
      const imgRef = this.itemRefs[selectedIndex];

      if (imgRef) {
        const node = imgRef.current;

        if (node) {
          const img = node.querySelector("img,video");

          listeners.forEach(event => {
            if ("function" === typeof this.listeners[event]) {
              this.listeners[event](img, selectedIndex, direction);
            }
          });
        }
      }
    }
  }

  /**
   * @description Register a new listener
   * @param {object} listeners - An object containing one or more events and their associated listening functions
   * @memberof AbstractCarousel
   */
  registerListener(listeners) {
    this.listeners = { ...this.listeners, ...listeners };
  }

  /**
   * @description Get the component SASS variable name
   * @returns {string}
   * @memberof AbstractCarousel
   */
  getComponentClassName() {
    return this.props.className;
  }

  /**
   * @description Get the extra properties that will be applied to the final carousel element
   * @param {Object} [props={}] Default props
   * @returns {object}
   * @memberof AbstractCarousel
   */
  getCarouselProps(props = {}) {
    const style = props.style || {};

    // fix the carousel height when not already defined/fixed
    if (this.props.autoHeight && !style.height && this.ref.current) {
      return {
        ...props,
        style: {
          ...style,
          height: this.ref.current.element.getBoundingClientRect().height
        }
      };
    }

    return props;
  }

  /**
   * @description Get the suffix that will be appended to the carousel item classname
   * @returns {string}
   * @memberof AbstractCarousel
   */
  getItemSuffix() {
    return null; // implemented by child class
  }

  /**
   * @description Get the carousel slide's inner element classname
   * @returns {string}
   * @memberof AbstractCarousel
   */
  getItemClass() {
    return null; // implemented by child class
  }

  /**
   * @description Get the carousel items that will be rendered (eg. props.items)
   * @returns {array}
   * @memberof AbstractCarousel
   */
  getCarouselItems() {
    return this.props.items;
  }

  /**
   * @description Render the carousel slide's inner element
   * @param {*} item The element from the props.items property
   * @param {number} index The element index
   * @param {Array} array The array the item belong to
   * @returns {JSX}
   * @memberof AbstractCarousel
   */
  renderItem(item, index, array) {
    this.classExtendError();
  }

  /**
   * @description Render a carousel slide
   * @param {*} item The element from the props.items property
   * @param {number} index The element index
   * @param {Array} array The array the item belong to
   * @returns {Carousel.Item}
   * @memberof AbstractCarousel
   */
  renderCarouselItem(item, index, array) {
    const dragEvents = {};

    if (this.props.dragSlide) {
      dragEvents.onMouseDown = e => {
        this.startX = e.pageX;
        e.preventDefault();
      };
      dragEvents.onMouseUp = e => {
        this.endX = e.pageX;
        const deltaX = this.endX - this.startX;

        if (Math.abs(deltaX) > this.threshold) {
          if (deltaX > 0) {
            const newIndex =
              this.state.index - 1 < 0
                ? this.getCarouselItems().length - 1
                : this.state.index - 1;
            // Swipe to the right goes to previous slide
            this.handleSelect(newIndex);
          } else {
            const newIndex =
              this.state.index + 1 > this.getCarouselItems().length - 1
                ? 0
                : this.state.index + 1;

            // Swipe to the left goes to next slide
            this.handleSelect(newIndex);
          }
        }
      };
      dragEvents.onMouseLeave = e => {
        // End the drag if the mouse leaves the carousel area
        this.endX = e.pageX;
        // Here you might want to handle partial drags
      };
    }

    return (
      <Carousel.Item
        ref={this.itemRefs[index]}
        key={index}
        className={getComponentClassName(
          this.getComponentClassName(),
          this.getItemSuffix(),
          this.getItemClass()
        )}
        style={item.style}
        {...dragEvents}
      >
        {this.renderItem(item, index, array)}
      </Carousel.Item>
    );
  }

  render() {
    const items = this.getCarouselItems().map(this.renderCarouselItem);

    const hasControls =
      !!this.props.controls &&
      this.props.items.length > 1 && // no point when there is only 1 item
      (!this.props.itemsPerSlide ||
        this.props.items.length > this.props.itemsPerSlide);

    const hasIndicators = this.props.indicators && this.props.items.length > 1;

    const defaultActiveIndex =
      "undefined" === typeof this.props.defaultActiveIndex
        ? this.state.index
        : this.props.defaultActiveIndex;

    let nextIcon =
      "function" === typeof this.props.nextIcon
        ? this.props.nextIcon({})
        : this.props.nextIcon;
    let prevIcon =
      "function" === typeof this.props.prevIcon
        ? this.props.prevIcon({})
        : this.props.prevIcon;

    const className = this.props.roundIcon
      ? "rounded"
      : "text-muted rounded-lg";
    const style = {};

    if (!this.props.roundIcon && !this.props.squareIcon) {
      style["--background-opacity"] = 0;
    }
    if ("undefined" !== this.props.iconOpacity) {
      style["--background-opacity"] = this.props.iconOpacity;
    }
    if ("undefined" !== this.props.iconHoverOpacity) {
      style["--hover-opacity"] = this.props.iconHoverOpacity;
    }
    if ("undefined" !== this.props.iconSize) {
      style["--icon-size"] = this.props.iconSize;
    }
    if ("undefined" !== this.props.iconPadding) {
      style["--icon-padding"] = this.props.iconPadding;
    }

    const controlIcon = icon =>
      icon ? (
        <div className={className} style={style}>
          {icon}
        </div>
      ) : null;

    nextIcon = controlIcon(nextIcon);
    prevIcon = controlIcon(prevIcon);

    return (
      <this.CarouselWrapper
        ref={this.ref}
        indicators={hasIndicators /*&& hasControls*/}
        activeIndex={this.state.index}
        defaultActiveIndex={defaultActiveIndex}
        direction={null /*this.state.direction*/}
        onSelect={this.handleSelect}
        interval={this.state.interval}
        nextIcon={nextIcon}
        prevIcon={prevIcon}
        controls={hasControls}
        className={getComponentClassName(
          this.getComponentClassName(),
          null,
          this.props.className
        )}
        {...this.getCarouselProps({
          style: this.props.style
        })}
      >
        {items}
      </this.CarouselWrapper>
    );
  }
}

const ControlIconPropTypes = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.string,
  PropTypes.object,
  PropTypes.func
]);

AbstractCarousel.propTypes = {
  ...ItemsAwareProps(
    false,
    null,
    PropTypes.oneOfType([PropTypes.array, PropTypes.shape(ImageProps)])
  ),
  activeIndex: PropTypes.number,
  defaultActiveIndex: PropTypes.number,
  indicators: PropTypes.bool,
  controls: PropTypes.bool,
  className: PropTypes.string,
  interval: PropTypes.number,
  intervalDelay: PropTypes.number,
  nextIcon: ControlIconPropTypes,
  prevIcon: ControlIconPropTypes,
  roundIcon: PropTypes.bool,
  squareIcon: PropTypes.bool,
  iconSize: PropTypes.string,
  iconPadding: PropTypes.string,
  iconOpacity: PropTypes.number,
  iconHoverOpacity: PropTypes.number,
  magnifier: PropTypes.bool,
  onSelect: PropTypes.func,
  showCaption: PropTypes.bool,
  placeholder: PropTypes.bool,
  autoHeight: PropTypes.bool,
  dragSlide: PropTypes.bool
};

AbstractCarousel.defaultProps = {
  indicators: false,
  controls: false,
  className: null,
  interval: null,
  intervalDelay: null,
  magnifier: false,
  showCaption: true,
  roundIcon: true,
  squareIcon: false,
  autoHeight: true,
  dragSlide: false
};
