import BootstrapFormCheck from "@components-core/BootstrapFormCheck";
import CustomFormRadio from "@components-core/CustomFormRadio";
import ExternalLink from "@components-core/ExternalLink";
import Picture from "@components-core/Picture";
import PureComponent from "@components-core/PureComponent";
import { connectHOCs } from "@components-utils";
import ListItemProps from "@prop-types/ListItemProps";
import ListItemsProps from "@prop-types/ListItemsProps";
import {
  addOrderValueChangeSubscriber,
  removeOrderValueChangeSubscriber
} from "@redux-actions/calculator";
import {
  addCartChangeSubscriber,
  removeCartChangeSubscriber
} from "@redux-actions/cart";
import {
  addOrderSubscriber,
  checkoutPaymentPrepare,
  removeOrderSubscription
} from "@redux-actions/checkout";
import {
  checkoutFetchPaymentMethodsFailure,
  checkoutFetchPaymentMethodsSuccess,
  checkoutSetPaymentMethod,
  fetchPaymentMethods
} from "@redux-actions/checkout-payment";
import {
  checkoutFetchShipmentMethodsFailure,
  checkoutFetchShipmentMethodsSuccess,
  checkoutSetShipmentMethod,
  fetchShipmentMethods
} from "@redux-actions/checkout-shipment";
import { errorAddUnhandledException } from "@redux-actions/error";
import {
  BasePaymentMethodBS,
  BaseShipmentMethodBS,
  CheckoutPaymentShipmentBS
} from "@style-variables";
import { getCloudinaryUrl } from "@utils/cloudinary";
import { scrollIntoView } from "@utils/functions";
import { escapeReact } from "@utils/react";
import {
  formatCurrency,
  formatNumber,
  getComponentClassName,
  htmlToText
} from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Badge, Col, Container, Form, Row } from "react-bootstrap";
import PaymentMethodIcons from "../PaymentMethod/Icons";

class CheckoutPaymentShipment extends PureComponent {
  // see also `state` and `mapStateToProps`
  static METHOD_PAYMENT = "payment";
  static METHOD_SHIPMENT = "shipment";

  static SHOW_METHOD_PRICE = {
    [CheckoutPaymentShipment.METHOD_PAYMENT]: false,
    [CheckoutPaymentShipment.METHOD_SHIPMENT]: true
  };
  static SHOW_METHOD_PRICE_NOTNULL_ONLY = false;

  constructor(props) {
    super(props);

    this.handlePaymentChange = this.handlePaymentChange.bind(this);
    this.handleShipmentChange = this.handleShipmentChange.bind(this);
    this.renderMethod = this.renderMethod.bind(this);
    this.renderPaymentMethodImg = this.renderPaymentMethodImg.bind(this);

    // listen to cart/order-change and order-submission actions
    this.onPlacingOrder = this.onPlacingOrder.bind(this);
    this.onOrderValueChange = this.onOrderValueChange.bind(this);
    this.onCartChange = this.onCartChange.bind(this);

    // see also METHOD_* constants
    this.state = { feedback: { payment: "", shipment: "" }, fetching: false };

    this.feedbackRef = {
      payment: React.createRef(),
      shipment: React.createRef()
    };

    this._isMounted = false;
  }

  handlePaymentChange(payment) {
    this.setState({ feedback: { ...this.state.feedback, payment: "" } });

    this.props.checkoutSetPaymentMethod(payment, this.props.siteConfig);
  }

  handleShipmentChange(shipment) {
    this.setState({ feedback: { ...this.state.feedback, shipment: "" } });
    this.props.checkoutSetShipmentMethod(shipment, this.props.siteConfig);
  }

  validateOption(type, option) {
    if ((!option || option.error) && this.props.hasOptions) {
      const _i18n = this.props.i18n.pages.CheckoutShipmentPayment;
      const type_i18n = type.toUpperCase() + "_TITLE";

      const feedback = _i18n.METHOD_NOT_SPECIFIED.replace(
        "%type%",
        _i18n[type_i18n]
      );

      this.setState({
        feedback: { ...this.state.feedback, [type]: feedback }
      });

      if (this.feedbackRef[type].current) {
        scrollIntoView(this.feedbackRef[type].current);
      }

      throw Error(feedback);
    }

    this.setState({
      feedback: { ...this.state.feedback, [type]: "" }
    });

    return option;
  }

  validatePaymentOption(option) {
    return this.validateOption(CheckoutPaymentShipment.METHOD_PAYMENT, option);
  }

  validateShipmentOption(option) {
    return this.validateOption(CheckoutPaymentShipment.METHOD_SHIPMENT, option);
  }

  onPlacingOrder() {
    return {
      shipment: this.validateShipmentOption(this.props.selectedShipmentMethod),
      payment: this.validatePaymentOption(this.props.selectedPaymentMethod)
    };
  }

  /**
   * @description Get the method that should be set as selected/default
   * @param {String} key One of payment|shipment
   * @param {Array} (data=null) A list of methods where one is hopefully set as default.
   * @returns {Object} Returns the method object on success, undefined otherwise
   * @memberof CheckoutPaymentShipment
   */
  getDefaultMethod(key, data = null) {
    // PRIORITY:
    // 1) MUST-TO-HAVE: submitted payload method (while checkout form submit)
    // 2) NICE-TO-HAVE: the current selected method, if any
    // 3) NICE-TO-HAVE: the one that is marked as default
    // 4) NICE-TO-HAVE: If none of the above applies then the first fetched method

    data = data || [];

    let selectedMethod =
      CheckoutPaymentShipment.METHOD_PAYMENT === key
        ? this.props.selectedPaymentMethod
        : CheckoutPaymentShipment.METHOD_SHIPMENT === key
        ? this.props.selectedShipmentMethod
        : null;

    // does the currently selected method still exists on the method list?
    if (selectedMethod) {
      const methodExists = data.some(
        method => method.value === selectedMethod.value
      );
      if (!methodExists) {
        selectedMethod = null;
      }
    }

    return this.props.payload && this.props.checkoutLocked
      ? this.props.payload[key] //1
      : data.find(
          option =>
            selectedMethod
              ? selectedMethod.value === option.value //2
              : option.isDefault //3
        ) || (data.length ? data[0] : undefined); //4
  }

  /**
   * @description Get the Redux action creators for the payent/shipment methods
   * @param {String} [key=null] Specify the method key to return. One of payment|shipment. When NULL then both are returned.
   * @returns {Object} Returns an object with a key for each method (payment|shipment)
   * @memberof CheckoutPaymentShipment
   */
  getReduxActionCreators(key = null) {
    const actions = {
      payment: {
        action: this.props.fetchPaymentMethods,
        resolve: this.props.checkoutFetchPaymentMethodsSuccess,
        reject: error =>
          this.props.checkoutFetchPaymentMethodsFailure(
            error,
            this.props.i18n.UNEXPECTED_ERROR_CAUSE.context
              .FETCH_CHECKOUT_PAYMENT_OPTION
          ),
        reset: this.handlePaymentChange
      },
      shipment: {
        action: this.props.fetchShipmentMethods,
        resolve: this.props.checkoutFetchShipmentMethodsSuccess,
        reject: error =>
          this.props.checkoutFetchShipmentMethodsFailure(
            error,
            this.props.i18n.UNEXPECTED_ERROR_CAUSE.context
              .FETCH_CHECKOUT_SHIPMENT_OPTION
          ),
        reset: this.handleShipmentChange
      }
    };

    if (key) {
      return { [key]: actions[key] };
    }

    return actions;
  }

  /**
   * @description Fetch the payment/shipment methods
   * @param {String} [key=null] Specify the method key to fetch. One of payment|shipment. When NULL then both are fetched.
   * @memberof CheckoutPaymentShipment
   */
  fetchData(key = null) {
    if (this.props.isCartEmpty) {
      return;
    }

    const actions = this.getReduxActionCreators(key);

    this.setState({ fetching: true });

    // NOTE: this should be done on `componentWillMount`, otherwise it won't refetch its payments methods, which we should, based on order value!
    Object.keys(actions).forEach(key => {
      let totalValue = this.props.cartValue;

      // when fetching payment methods DO NOT compare against the order value including payment fees (avoid chicken-egg problem)
      if (CheckoutPaymentShipment.METHOD_PAYMENT === key) {
        totalValue =
          this.props.orderValue -
          (this.getDefaultMethod(key) || { amount: 0 }).amount;
      }

      actions[key]
        .action(totalValue, this.props.siteConfig)
        .then(data => {
          if (this._isMounted) {
            this.setState({ fetching: false });

            // reset the eventual selected option (forces/simulates method selection)
            actions[key].reset(this.getDefaultMethod(key, data));

            actions[key].resolve(data, this.props.cart);
          }
        })
        .catch(reason => {
          if (this._isMounted) {
            this.setState({ fetching: false });
          }
          actions[key].reject(reason);
        });
    });
  }

  onOrderValueChange({ prevResult, newResult }) {
    if (
      this.props.visibleGroups.includes(CheckoutPaymentShipment.METHOD_PAYMENT)
    ) {
      // the payment methods varies with the ORDER value
      // we refetch these methods each time the ORDER content (thus probably its value) changes
      this.fetchData(CheckoutPaymentShipment.METHOD_PAYMENT);
    }
  }

  onCartChange(type, product, preorder, quantity, updated) {
    if (
      this.props.visibleGroups.includes(CheckoutPaymentShipment.METHOD_SHIPMENT)
    ) {
      // the shipment methods varies with the CART value
      // we refetch these methods each time the CART content (thus probably its value) changes
      this.fetchData(CheckoutPaymentShipment.METHOD_SHIPMENT);
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  // see https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
  UNSAFE_componentWillMount() {
    if (!this.props.isCartEmpty) {
      this.props.checkoutPaymentPrepare();

      const key = this.props.visibleGroups.includes(
        CheckoutPaymentShipment.METHOD_PAYMENT
      )
        ? this.props.visibleGroups.includes(
            CheckoutPaymentShipment.METHOD_SHIPMENT
          )
          ? null
          : CheckoutPaymentShipment.METHOD_PAYMENT
        : CheckoutPaymentShipment.METHOD_SHIPMENT;

      this.fetchData(key);
    }

    // subscribe to CART change notification
    this.props.addCartChangeSubscriber(this.onCartChange);

    // subscribe to order submission validation
    this.props.addOrderSubscriber(this.onPlacingOrder, 2); // this should have the immediate priority order after the address since its page order is right after the address block

    // subscribe to ORDER change notification
    this.props.addOrderValueChangeSubscriber(this.onOrderValueChange);
  }

  componentWillUnmount() {
    // unsubscribe from CART change notification
    this.props.removeOrderValueChangeSubscriber(this.onOrderValueChange);

    // unsubscribe from order submission validation
    this.props.removeOrderSubscription(this.onPlacingOrder);

    // unsubscribe from ORDER change notification
    this.props.removeCartChangeSubscriber(this.onCartChange);

    this._isMounted = false;
  }

  /**
   * @description Renders the payment method logo
   * @param {Object} obj The logo object
   * @returns {Picture}
   * @memberof CheckoutPaymentShipment
   */
  renderPaymentMethodImg(obj) {
    return obj.src ? (
      <Picture
        key={obj.src}
        src={getCloudinaryUrl({
          src: this.props.svgDir + "/" + obj.src,
          cloudinary: this.props.cloudinary,
          seoSuffix: obj.src,
          ...obj.size
        })}
        imgSize={obj.size}
        style={{ minHeight: obj.size.minHeight }}
        alt={obj.src}
        title={obj.src}
      />
    ) : null;
  }

  renderMethod(method) {
    const mdSize = method.children && method.inlineChildren ? 4 : 12;

    const children = method.children ? (
      <Col xs="12" sm="12" md={12 - mdSize || null} className="px-1">
        {method.children}
      </Col>
    ) : null;

    const currency = this.props.currency;

    const methodPrice = CheckoutPaymentShipment.SHOW_METHOD_PRICE[method.type]
      ? method.amount || !CheckoutPaymentShipment.SHOW_METHOD_PRICE_NOTNULL_ONLY
        ? ", " +
          formatCurrency(
            formatNumber(method.amount, currency.decimal, currency.thousand),
            currency.prefix,
            currency.suffix
          )
        : null
      : null;

    const label = (
      <React.Fragment>
        {escapeReact(method.title, this.props.pathfinder)}
        {methodPrice}
      </React.Fragment>
    );

    const simulatedPaymentLink = method.testEnvironment
      ? method.payment_type.startsWith(`payment_adyen`)
        ? "https://docs.adyen.com/development-resources/test-cards/test-card-numbers"
        : method.payment_type.startsWith(`payment_paypal`)
        ? "https://developer.paypal.com/tools/sandbox"
        : null
      : null;

    const logo =
      "payment" === method.type && method.img
        ? Array.isArray(method.img)
          ? method.img.map(this.renderPaymentMethodImg)
          : this.renderPaymentMethodImg(method.img)
        : null;

    const logoBar =
      this.props.showPaymentsLogo &&
      (!method.checked || this.props.showSelectedPaymentLogo) ? (
        <div className="d-flex float-right align-items-center logo-bar">
          {logo}
        </div>
      ) : null;

    return (
      <Row
        className={getComponentClassName(
          method.name,
          null,
          method.className || method.type + "-method"
        )}
      >
        <Col xs="12" sm="12" md={mdSize} className="px-1">
          <BootstrapFormCheck
            id={method.id}
            name={method.name}
            type="radio"
            value={method.value}
            checked={method.checked}
            label={
              method.testEnvironment ? (
                <div>
                  {label}
                  <ExternalLink href={simulatedPaymentLink}>
                    <Badge className="mx-2" variant="warning">
                      SIMULATED PAYMENT
                    </Badge>
                  </ExternalLink>
                </div>
              ) : (
                label
              )
            }
            onChange={method.onChange}
            inline
            ariaLabel={htmlToText(method.title)}
            disabled={this.props.checkoutLocked || method.disabled}
          />
          {logoBar}
        </Col>
        {children}
      </Row>
    );
  }

  getPaymentShipmentMethods() {
    const obj = {};

    const _i18n = this.props.i18n.pages.CheckoutShipmentPayment;

    if (
      this.props.visibleGroups.includes(CheckoutPaymentShipment.METHOD_SHIPMENT)
    ) {
      obj.shipment = {
        className: BaseShipmentMethodBS,
        onChange: this.handleShipmentChange
      };
    }
    if (
      this.props.visibleGroups.includes(CheckoutPaymentShipment.METHOD_PAYMENT)
    ) {
      obj.payment = {
        className: BasePaymentMethodBS,
        onChange: this.handlePaymentChange
      };
    }

    const methods = {};

    Object.keys(obj).forEach(key => {
      if (this.props[key].items.length) {
        const props = {
          ...this.props[key],
          items: this.props[key].items.map(item => ({
            ...item,
            type: key
          }))
        };

        methods[key] = (
          <Container
            className={getComponentClassName(
              CheckoutPaymentShipmentBS,
              null,
              "px-0"
            )}
          >
            <Row>
              <Col className="px-1">
                <h6 className="font-weight-bold">
                  {_i18n.CHOOSE_METHOD_TITLE.replace(
                    /%METHOD%/g,
                    this.props[key].title
                  )}
                </h6>
              </Col>
            </Row>
            <Row>
              <Col className="px-1">
                <CustomFormRadio
                  as={this.renderMethod}
                  {...props}
                  className={obj[key].className}
                  onChange={obj[key].onChange}
                />
              </Col>
            </Row>
          </Container>
        );
      }
    });

    return methods;
  }

  render() {
    if (
      !this.props.hasOptions ||
      this.props.isCartEmpty ||
      this.state.fetching
    ) {
      return null;
    }

    const methods = this.getPaymentShipmentMethods();
    if (!methods.shipment && !methods.payment) {
      return null;
    }

    const feedback = this.state.feedback;

    const shipments = methods.shipment ? (
      <Form.Group ref={this.feedbackRef.shipment}>
        <Col>{methods.shipment}</Col>
        <Form.Control.Feedback
          type="invalid"
          style={{ display: feedback.shipment ? "block" : "none" }}
        >
          {feedback.shipment}
        </Form.Control.Feedback>
      </Form.Group>
    ) : null;

    const payments = methods.payment ? (
      <React.Fragment>
        <Form.Group className="mt-3 pt-3" ref={this.feedbackRef.payment}>
          <Col>{methods.payment}</Col>
          <Form.Control.Feedback
            type="invalid"
            style={{ display: feedback.payment ? "block" : "none" }}
          >
            {feedback.payment}
          </Form.Control.Feedback>
        </Form.Group>
        {/* LOGO */}
        <Row className="text-right">
          <Col>
            <PaymentMethodIcons items={this.props.paymentMethods} />
          </Col>
        </Row>
      </React.Fragment>
    ) : null;

    // const uiBlockClassname =
    //   this.props.checkoutLocked && this.props.additionalPaymentDetails //|| this.props.placeOrderStatus
    //     ? "ui-block-unblock"
    //     : null;

    const uiBlockClassname = null;

    const title = this.props.visibleGroups.includes(
      CheckoutPaymentShipment.METHOD_SHIPMENT
    )
      ? this.props.visibleGroups.includes(
          CheckoutPaymentShipment.METHOD_PAYMENT
        )
        ? this.props.setup.title
        : this.props[CheckoutPaymentShipment.METHOD_SHIPMENT].title
      : this.props[CheckoutPaymentShipment.METHOD_PAYMENT].title;

    return (
      <div>
        <div
          className={getComponentClassName(
            CheckoutPaymentShipmentBS,
            null,
            [this.props.className, uiBlockClassname, "callout-top"]
              .filter(Boolean)
              .join(" ")
          )}
        >
          <div className="step-title mb-4">
            {this.props.i18n.pages.CheckoutShoppingCart.STEP_PREFIX.replace(
              /%NAME%/g,
              this.props.optionPrefix + " - " + title
            )}
          </div>
          <Container className="mx-lg-3 px-0">
            {shipments}
            {payments}
          </Container>
        </div>
      </div>
    );
  }
}

CheckoutPaymentShipment.propTypes = {
  className: PropTypes.string,
  optionPrefix: PropTypes.string,
  setup: PropTypes.shape({ title: PropTypes.string.isRequired }),
  shipment: PropTypes.shape({
    ...ListItemsProps(PropTypes.shape(ListItemProps))
  }),
  payment: PropTypes.shape({
    ...ListItemsProps(PropTypes.shape(ListItemProps))
  }),
  showPaymentsLogo: PropTypes.bool,
  showSelectedPaymentLogo: PropTypes.bool,
  visibleGroups: PropTypes.arrayOf(
    PropTypes.oneOf([
      CheckoutPaymentShipment.METHOD_PAYMENT,
      CheckoutPaymentShipment.METHOD_SHIPMENT
    ])
  )
};

CheckoutPaymentShipment.defaultProps = {
  showPaymentsLogo: true,
  showSelectedPaymentLogo: false,
  visibleGroups: [
    CheckoutPaymentShipment.METHOD_PAYMENT,
    CheckoutPaymentShipment.METHOD_SHIPMENT
  ]
};

const getOptionPrefix = (state, ownProps) => {
  let optionPrefix = ownProps.optionPrefix;

  // the fetched options
  const fetchedOptions = state.checkoutOtherOptionsResult
    ? Object.keys(state.checkoutOtherOptionsResult).filter(
        key => (state.checkoutOtherOptionsResult[key].options || []).length
      )
    : [];

  return +optionPrefix + Boolean(fetchedOptions.length);
};

// ------------------- REDUX ----------------------

CheckoutPaymentShipment.mapStateToProps = (state, ownProps) => {
  let paymentItems = state.checkoutPaymentMethodsResult.methods || [];
  let shipmentItems = state.checkoutShipmentMethodsResult.methods || [];
  const paymentMethod = state.checkoutPayment.paymentMethod;
  const shipmentMethod = state.checkoutShipment.shipmentMethod;

  // set the selected payment method as the one set on Redux store (see handlePaymentChange)
  if (paymentMethod) {
    paymentItems = paymentItems.map(method => ({
      ...method,
      isDefault:
        method.value === paymentMethod.value || paymentItems.length === 1
    }));
  }

  // set the selected shipment method as the one set on Redux store (see handleShipmentChange)
  if (shipmentMethod) {
    shipmentItems = shipmentItems.map(method => ({
      ...method,
      isDefault:
        method.value === shipmentMethod.value || shipmentItems.length === 1
    }));
  }

  const gross = state.calculatorResult.gross || {};

  return {
    optionPrefix: getOptionPrefix(state, ownProps), // the option block title's prefix
    cart: state.cart.items,
    isCartEmpty: !state.cart.items.length,
    hasOptions: paymentItems.length + shipmentItems.length > 0,

    // see also `state` and METHOD_* constants
    payment: {
      ...ownProps.payment,
      items: paymentItems
    },
    selectedPaymentMethod: paymentMethod,
    // see also `state` and METHOD_* constants
    shipment: {
      ...ownProps.shipment,
      items: shipmentItems
    },
    selectedShipmentMethod: shipmentMethod,
    //
    orderValue: gross.orderValue || 0,
    cartValue: gross.cartValue || 0,
    //
    checkoutLocked:
      (state.placeOrderResult.isFetching ||
        state.placeOrderResult.status ||
        state.checkout.paymentState.initiated) &&
      !(
        state.checkout.paymentState.success ||
        state.checkout.paymentState.failure
      ),
    additionalPaymentDetails: state.checkout.paymentState.additionalDetails,
    placeOrderStatus: state.placeOrderResult.status,
    payload: state.checkout.payload
  };
};

CheckoutPaymentShipment.mapDispatchToProps = {
  fetchPaymentMethods,
  checkoutFetchPaymentMethodsSuccess,
  checkoutFetchPaymentMethodsFailure,
  fetchShipmentMethods,
  //
  checkoutFetchShipmentMethodsSuccess,
  checkoutFetchShipmentMethodsFailure,
  //
  addOrderSubscriber,
  removeOrderSubscription,
  //
  checkoutPaymentPrepare,
  checkoutSetPaymentMethod,
  checkoutSetShipmentMethod,
  //
  errorAddUnhandledException,
  //
  addCartChangeSubscriber,
  removeCartChangeSubscriber,
  addOrderValueChangeSubscriber,
  removeOrderValueChangeSubscriber
};

CheckoutPaymentShipment.mapValueToProps = value => {
  return {
    currency: value.currency,
    svgDir: value.svgDir,
    cloudinary: value.cloudinary,
    ...value.checkout.shipmentPayment
  };
};

export default connectHOCs(CheckoutPaymentShipment, {
  withConnect: true,
  withSite: true,
  withGraphQL: true
});
