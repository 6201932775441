/**
 * @description Extract the parent directory name of a file
 * @param {String} filename The filename
 * @param {string} [separator="/"] The path separator
 * @returns {String} Returns the parent directory name including the separator
 */
function dirName(filename, separator = "/") {
  return filename.replace(
    new RegExp(`(.*[\\${separator}])?\\${separator}[^\\${separator}]*$`),
    "$1"
  );
}

/**
 * @description Extract the base name of a filename
 * @param {String} filename
 * @param {Boolean} [exclExtension = false] When true extract the base name excluding the file extension
 * @param {String} [separator = "/"] The path separator
 * @returns {String}
 */
function baseName(filename, exclExtension = false, separator = "/") {
  const re = new RegExp(`.*\\${separator}`, "g");

  const result = filename ? filename.replace(re, "") : "";

  return exclExtension ? stripExtension(result) : result;
}

/**
 * @description Strip the extension part of a filename
 * @param {String} filename The filename
 * @returns {String} The filename without the extension
 */
function stripExtension(filename) {
  const index = filename.lastIndexOf(".");
  return index > -1 ? filename.slice(0, index) : filename;
}

/**
 * @description Removes the trailing slash from path
 * @param {String} path The path
 * @param {String} [separator="/"] The path separator
 * @returns {String}
 */
function removeTrailingSlash(path, separator = "/") {
  if (path.slice(-1) === separator) {
    return path.slice(0, -1);
  }

  return path;
}

/**
 * @description Get the extension part of a filename
 * @param {String} filename The filename
 * @param {boolean} [includeDot=true] When true then include the dot, otherwise strip it
 * @returns {String} The filename's extension
 */
function getExtension(filename, includeDot = true) {
  const index = filename.lastIndexOf(".");
  return index > -1 ? filename.slice(index + +!includeDot) : "";
}

/**
 * @description Check whether the given filename has a given file extension
 * @param {String} filename The filename
 * @param {String} ext The checked file extension
 * @returns {boolean} Returns true if the filename has the given extension, false otherwise
 */
function fileHasExtension(filename, ext) {
  return ext.toUpperCase() === getExtension(filename, false).toUpperCase();
}

/**
 * @description Save the content to local filesystem
 * @param {string|ArrayBuffer} content
 * @param {string} filename
 * @param {string} [contentType="application/octet-stream"]
 */
function saveToDisk(
  content,
  filename,
  contentType = "application/octet-stream"
) {
  try {
    // Convert input to Blob based on type
    let blob;
    if (typeof content === "string") {
      // Handle string input (could be plain text or base64)
      if (content.startsWith("data:")) {
        // Handle base64 data URI
        const byteString = atob(content.split(",")[1]);
        const mimeString = content.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        blob = new Blob([ab], { type: mimeString });
      } else {
        // Plain text string
        blob = new Blob([content], { type: contentType });
      }
    } else if (content instanceof ArrayBuffer) {
      // Handle ArrayBuffer input
      blob = new Blob([content], { type: contentType });
    } else {
      throw new Error(
        "Unsupported content type. Expected string or ArrayBuffer."
      );
    }

    // Create temporary URL and trigger download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

    console.log(`File download initiated as ${filename}`);
  } catch (error) {
    console.error("Error saving file:", error);
    throw error; // Re-throw to allow caller to handle if needed
  }
}

export {
  dirName,
  baseName,
  stripExtension,
  getExtension,
  fileHasExtension,
  removeTrailingSlash,
  saveToDisk
};
