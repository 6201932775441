import { connectHOCs } from "@components-utils";
import {
  PRODUCT_SELECTOR_TYPE_BRAND,
  PRODUCT_SELECTOR_TYPE_CATEGORY,
  PRODUCT_SELECTOR_TYPE_PRODUCT,
  PRODUCT_SELECTOR_TYPE_RELATED,
  PRODUCT_SELECTOR_TYPE_SERIES
} from "@constants";
import GraphQLComponent from "@graphql-component";
import {
  EQUAL,
  FILTER_VALUE_ARRAY_INT,
  FILTER_VALUE_ARRAY_STRING,
  FILTER_VALUE_INTEGER,
  FILTER_VALUE_STRING,
  IN
} from "@graphql-operators";
import gqlProductFileFieldsFragment from "@graphql-query/productFileFieldsFragment.gql";
import gqlProductImageFieldsFragment from "@graphql-query/productImageFieldsFragment.gql";
import gqlProductImageFragment from "@graphql-query/productImageFragment.gql";
import gqlProducts from "@graphql-query/products.gql";
import productsWithinCategory from "@graphql-query/productsWithinCategory.gql";
import gqlProductsWithinSeries from "@graphql-query/productsWithinSeries.gql";
import gqlProductsWithinTrademark from "@graphql-query/productsWithinTrademark.gql";
import gqlRelatedProduct from "@graphql-query/relatedProduct.gql";
import gqlRelatedProductFragment from "@graphql-query/relatedProductFragment.gql";
import gqlSEOScoreFragment from "@graphql-query/seoScoreFragment.gql";
import { ProductSliderTransformer } from "@transformers/Product";
import React from "react";
import ProductSlider from "./Slider";

const gqlQuery = selectorType => {
  switch (selectorType) {
    case PRODUCT_SELECTOR_TYPE_PRODUCT:
      return [gqlProducts, gqlProductFileFieldsFragment];
    case PRODUCT_SELECTOR_TYPE_CATEGORY:
      return [productsWithinCategory];
    case PRODUCT_SELECTOR_TYPE_RELATED:
      return [gqlRelatedProduct];
    case PRODUCT_SELECTOR_TYPE_BRAND:
      return [gqlProductsWithinTrademark];
    case PRODUCT_SELECTOR_TYPE_SERIES:
      return [gqlProductsWithinSeries];
    default:
      throw new Error(`Unexpected value for selectorType = "${selectorType}"`);
  }
};

const gqlVariables = (props, selectorType) => {
  const getVar = key => {
    if (!props[key]) return null;

    let operator = EQUAL;
    let operand = props.searchKey || props[key];
    let type = Number.isInteger(+operand)
      ? FILTER_VALUE_INTEGER
      : FILTER_VALUE_STRING;

    if (FILTER_VALUE_INTEGER === type) {
      operand = +operand;
    }

    // non-scalar comma-delimited key value
    if (-1 !== props[key].indexOf(",")) {
      operand = props[key]
        .split(",")
        .filter(Boolean)
        .map(value => (Number.isInteger(+value) ? +value : value));
      operator = IN;

      type = operand.some(value => "number" !== typeof value)
        ? FILTER_VALUE_ARRAY_STRING
        : FILTER_VALUE_ARRAY_INT;
    }

    return {
      filterBy: [
        props.graphqlClient.filterInput(
          !type ||
            [FILTER_VALUE_ARRAY_STRING, FILTER_VALUE_STRING].includes(type)
            ? "searchKey"
            : "id",
          operand,
          operator,
          type
        )
      ]
    };
  };

  switch (selectorType) {
    case PRODUCT_SELECTOR_TYPE_RELATED:
      return {
        includeRelated: true,
        includeChildren: false,
        searchKey: props.match.params.productId
      };
    case PRODUCT_SELECTOR_TYPE_CATEGORY:
      return getVar("searchKey") || getVar("categoryId");
    case PRODUCT_SELECTOR_TYPE_PRODUCT:
    case PRODUCT_SELECTOR_TYPE_BRAND:
    case PRODUCT_SELECTOR_TYPE_SERIES:
      return getVar("searchKey") || getVar("productId");
    default:
      throw new Error(`Unexpected value for selectorType = "${selectorType}"`);
  }
};

const getData = (data, selectorType) => {
  switch (selectorType) {
    case PRODUCT_SELECTOR_TYPE_BRAND:
      return data.productsWithinTrademark;
    case PRODUCT_SELECTOR_TYPE_SERIES:
      return data.productsWithinSeries;
    case PRODUCT_SELECTOR_TYPE_CATEGORY:
      return data.productsWithinCategory;
    case PRODUCT_SELECTOR_TYPE_RELATED:
      return (data.products || []).length ? data.products[0].related : [];
    case PRODUCT_SELECTOR_TYPE_PRODUCT:
      return data.products;
    default:
      throw new Error(`Unexpected value for selectorType = "${selectorType}"`);
  }
};

const SiteProductSlider = props => {
  const impressionList = props.searchKey;

  const selectorType = props.selectorType;

  const gqlProps = {
    graphqlClient: props.graphqlClient,
    query: [
      ...gqlQuery(selectorType),
      gqlProductImageFragment,
      gqlRelatedProductFragment,
      gqlProductImageFieldsFragment,
      gqlSEOScoreFragment
    ],
    variables: {
      siteId: props.siteId,
      ...gqlVariables(props, selectorType)
    },

    dataTransformer: data => {
      let items = getData(data, selectorType);

      // enforce items order as given by the search key
      if (props.enforceItemsOrder) {
        let searchKeys;
        switch (selectorType) {
          case PRODUCT_SELECTOR_TYPE_RELATED:
            searchKeys = props.productId;
            break;
          case PRODUCT_SELECTOR_TYPE_CATEGORY:
            searchKeys = props.searchKey || props.categoryId;
            break;
          case PRODUCT_SELECTOR_TYPE_PRODUCT:
          case PRODUCT_SELECTOR_TYPE_BRAND:
          case PRODUCT_SELECTOR_TYPE_SERIES:
            searchKeys = props.searchKey || props.productId;
            break;
          default:
        }

        if (
          searchKeys &&
          ![
            PRODUCT_SELECTOR_TYPE_CATEGORY,
            PRODUCT_SELECTOR_TYPE_BRAND,
            PRODUCT_SELECTOR_TYPE_SERIES
          ].includes(selectorType)
        ) {
          items = searchKeys
            .split(",")
            .map(id => items.find(item => id === item.id))
            .filter(Boolean);
        }
      }

      const passthrough = {
        siteId: props.siteId,
        userId: props.userId,
        i18n: props.i18n,
        pathfinder: props.pathfinder,
        graphqlClient: props.graphqlClient,
        selectorType,
        impressionList: selectorType + "/" + props.searchKey,
        itemsPerSlide: props.itemsPerSlide,
        className: props.className,
        title: props.title,
        titleAs: props.titleAs,
        text: props.text,
        getState: props.getState,
        dispatch: props.dispatch
      };

      return ProductSliderTransformer(items || [], passthrough);
    },
    wraps: wrappedProps => (
      <ProductSlider
        impressionList={impressionList}
        selectorType={selectorType}
        {...wrappedProps}
      />
    )
  };

  return <GraphQLComponent {...gqlProps} />;
};

SiteProductSlider.mapStateToProps = (state, ownProps) => ({
  getState: () => state
});

export default connectHOCs(SiteProductSlider, { withAll: true });
