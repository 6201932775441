import React from "react";

//exports a new instance of the class
const defaultSite = {};

const SiteContext = React.createContext(defaultSite);

SiteContext.displayName = "SiteContext";

const SiteProvider = SiteContext.Provider;

const SiteConsumer = SiteContext.Consumer;

/**
 * @description Injects the site context into the final Consumer component
 * @param {Object|Function} Consumer The React component that consumes the context
 * @returns {function}
 */
const withSiteContext = Consumer => {
  const fn = props => (
    <SiteContext.Consumer>
      {value => {
        const loggedUser = value.loggedUser || {};

        const siteId = +(loggedUser.siteId || value.siteId);

        const currency = require(`./${siteId}/json/currency.json`);

        const defaultProps = {
          siteId,
          userId: loggedUser.userId ? +loggedUser.userId : null,
          customerId: loggedUser.customerId ? +loggedUser.customerId : null,
          customerNumber: loggedUser.customerNumber,
          pathfinder: value.pathfinder,
          i18n: value.i18n,
          paymentApi: value.paymentApi,
          graphqlClient: props.graphqlClient
        };

        const valueProps =
          "function" === typeof Consumer.mapValueToProps
            ? Consumer.mapValueToProps({ ...value, currency }, props)
            : undefined; /*: { siteContext: value }*/

        const templateProps =
          "function" === typeof Consumer.mapValueToTemplateProps
            ? Consumer.mapValueToTemplateProps(value, props)
            : undefined;

        return (
          <Consumer
            {...{
              ...props,
              ...defaultProps,
              siteConfig: defaultProps,
              security: value.security,
              ...valueProps,
              ...templateProps
            }}
          />
        );
      }}
    </SiteContext.Consumer>
  );

  // let's return the component real name instead of an `unknown`
  Object.defineProperty(fn, "name", {
    value: "WithSite" + (Consumer.name || Consumer.displayName),
    configurable: true
  });

  return fn;
};

export { SiteContext, SiteProvider, SiteConsumer, withSiteContext };
