import React from "react";
import { SE_TAG_SEARCHBOX_ONLY } from "@constants";
import { debug } from "@utils/debug";
import MenuBarButton, { CustomMenuBarButton } from "./Button";

// safely extract dynamically the custom search engine identifiers
const extractComponentProps = () => {
  let m = {};

  try {
    m = require("../SearchEngine/Custom");
  } catch (error) {
    debug(error, "warn");
  }

  return ["ID", "DOCKED"].reduce(
    (carry, key) => Object.assign(carry, { [key]: m[key] }),
    {}
  );
};

const { ID: CUSTOMSE_ID } = extractComponentProps();

class MenuBarSearchButton extends MenuBarButton {
  renderButtonIcon() {
    return (
      <CustomMenuBarButton title={this.props.title}>
        <line x1="462" x2="665" y1="498" y2="712" />
        <circle cx="286" cy="286" r="276" />
      </CustomMenuBarButton>
    );
  }

  /**
   * @inherit
   * @memberof MenuBarSearchButton
   */
  renderButtonChildren() {
    if (!this.props.searchboxDocked) {
      return super.renderButtonChildren();
    }

    const id = CUSTOMSE_ID;

    const gseWidget = this.props.i18n.pages.FooterLinkSections.widgets.find(
      widget => id === widget.predefined
    );

    if (!gseWidget) {
      debug(
        `No ${id} widget found on ${this.props.siteId}/frontend/i18n/pages/FooterLinkSections.json`,
        "warn"
      );

      return null;
    }

    const getId = key =>
      [gseWidget.predefined || gseWidget.id, key, gseWidget.identity]
        .filter(Boolean)
        .join("-");

    return <div id={getId(SE_TAG_SEARCHBOX_ONLY)} />;
  }
}

export default MenuBarSearchButton;
