// the content-types entries cache
const contentTypeCache = {};

// the test-url entries cache
const testUrlCache = {};

// the download-url entries cache
const downloadUrlCache = {};

/**
 * @description Get the resource content type (via HEAD)
 * @param {String} url The url address
 * @param {Object} [options={}] The fetch options
 * @param {Boolean} [cache=false] When true cache the result
 * @returns {Promise} Returns a promise that returns the content-type header on success
 */
function getContentType(url, options = {}, cache = false) {
  if (cache && contentTypeCache[url]) {
    return Promise.resolve(contentTypeCache[url]);
  }

  const init = {
    method: "HEAD",
    redirect: "follow",
    //mode: "no-cors", // test-mode ONLY
    ...options
  };

  // https://developer.mozilla.org/en-US/docs/Web/API/fetch
  return fetch(url, init).then(response => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Response
    if (response.ok || (0 === response.status && "no-cors" === init.mode)) {
      const result = response.headers.get("content-type");

      if (cache) {
        contentTypeCache[url] = result;
      }

      return result;
    }

    return Promise.reject(
      new Error(response.statusText || `HTTP ${response.status} error`)
    );
  });
}

/**
 * @description Test if the URL exists (via HEAD)
 * @param {String} url The url address
 * @param {Object} [options={}] The fetch options
 * @param {Boolean} [cache=false] When true cache the result
 * @returns Returns a promise that resolves to the response headers on success
 */
function testUrl(url, options = {}, cache = false) {
  if (cache && testUrlCache[url]) {
    return Promise.resolve(testUrlCache[url]);
  }

  const init = {
    method: "HEAD",
    redirect: "follow",
    //mode: "no-cors", // test-mode ONLY
    ...options
  };

  // https://developer.mozilla.org/en-US/docs/Web/API/fetch
  return fetch(url, init).then(response => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Response
    if (response.ok || (0 === response.status && "no-cors" === init.mode)) {
      const result = response.headers;

      if (cache) {
        testUrlCache[url] = result;
      }

      return result;
    }

    return Promise.reject(
      new Error(response.statusText || `HTTP ${response.status} error`)
    );
  });
}

/**
 * @description Download a file by URL
 * @param {String} url The url address
 * @param {Object} [options={}] The fetch options
 * @param {Boolean} [cache=undefined] When true cache and/or use the cached result
 * @param {string} [encoding="utf8"]
 * @returns {Promise} Returns a promise that resolves the response data with respect to the given encoding
 */
function downloadUrl(url, options = {}, cache = undefined, encoding = "utf8") {
  if (cache && downloadUrlCache[url]) {
    return Promise.resolve(downloadUrlCache[url]);
  }

  const init = {
    method: "GET",
    redirect: "follow",
    // https://developer.mozilla.org/en-US/docs/Web/API/Request/cache#value
    cache:
      "undefined" === typeof cache
        ? "default"
        : cache
        ? "force-cache"
        : "no-cache",
    //mode: "no-cors", // test-mode ONLY
    ...options
  };

  // https://developer.mozilla.org/en-US/docs/Web/API/fetch
  return fetch(url, init).then(response => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Response
    if (response.ok || (0 === response.status && "no-cors" === init.mode)) {
      let promise;

      switch ((encoding || "utf8").toLowerCase()) {
        case "utf8":
          promise = response.text();
          break;
        case "base64":
          promise = response
            .arrayBuffer()
            .then(buffer =>
              Buffer.from(new Uint8Array(buffer)).toString("base64")
            );
          break;
        case "raw":
          promise = response.arrayBuffer();
          break;
        default:
          promise = Promise.reject(
            new Error(`Unexpected encoding option ${encoding}`)
          );
      }

      return promise.then(result => {
        if (cache) {
          downloadUrlCache[url] = result;
        }

        return result;
      });
    }

    return Promise.reject(
      new Error(response.statusText || `HTTP ${response.status} error`)
    );
  });
}

export { getContentType, testUrl, downloadUrl };
