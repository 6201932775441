import Picture from "@components-core/Picture";
import PureComponent from "@components-core/PureComponent";
import RouteLink from "@components-core/RouteLink";
import { connectHOCs } from "@components-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductProps from "@prop-types/ProductProps";
import { ProductCategoryComparatorItemBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Card, Col, Container, Nav, Row } from "react-bootstrap";
import ProductButtons from "../Product/Buttons";
import ProductPrice from "../Product/Price";
import ProductRating from "../Product/Rating";
import ProductTitle from "../Product/Title";

class ProductCategoryComparatorItem extends PureComponent {
  constructor(props) {
    super(props);

    this.handleItemRemoveClick = this.handleItemRemoveClick.bind(this);
  }

  handleItemRemoveClick(id) {
    this.props.compareItemRemove(id);
  }

  /**
   * @description Helper for getting the subcomponent classname
   * @param {string} suffix The suffix to join (by dash) to the baseclass()
   * @param {string} [otherClassName=null] When given a string to join (by space) to the resulted classname
   * @returns {String} Returns a classname derived from the main component classname
   * @memberof ProductCategoryItem
   */
  getClassName(suffix, otherClassName = null) {
    return getComponentClassName(
      ProductCategoryComparatorItemBS,
      suffix,
      otherClassName
    );
  }

  renderCompareItemRemove() {
    const i18n = this.props.i18n.components.ProductCategorySelector;

    return (
      <div
        className="position-absolute text-dark mx-2 cursor-pointer"
        aria-label="Close"
        title={i18n.BTN_FILTER_REMOVE}
        style={{ right: 0, zIndex: 1 }}
        onClick={e => this.props.onClick(this.props.id)}
      >
        <FontAwesomeIcon icon="times" />
      </div>
    );
  }

  renderThumbnail() {
    if (!this.props.img || !this.props.showThumbnail) {
      return null;
    }

    let imgProps = { title: this.props.title };

    if (typeof this.props.img === "string") {
      imgProps.src = this.props.img;
    }
    if (typeof this.props.img === "object") {
      imgProps = { ...imgProps, ...this.props.img };
    }

    return (
      <RouteLink
        to={this.props.buttons.product.href}
        title={this.props.title}
        className="text-center"
      >
        <Nav.Link as="div" className="mx-0 my-1">
          <Picture
            {...imgProps}
            onClick={this.props.buttons.product.onClick}
            className="card-img card-img-top"
            imgSize={{
              ...this.props.imgSize,
              maxHeight: undefined,
              height: this.props.imgSize.maxHeight
            }}
          />
        </Nav.Link>
      </RouteLink>
    );
  }

  renderItemBody() {
    return (
      <Card.Body className={this.getClassName("summary", "py-0 px-1")}>
        <ProductTitle
          title={this.props.title}
          className={this.getClassName("title")}
          onClick={this.props.buttons.product.onClick}
        />
      </Card.Body>
    );
  }

  renderItemPrice() {
    return (
      <ProductPrice
        i18n={this.props.i18n.components.Product}
        newPrice={this.props.newPrice}
        currency={this.props.currency}
        inline
      />
    );
  }

  renderItemRating() {
    return (
      <ProductRating
        rating={this.props.rating}
        className={this.getClassName("rating")}
      />
    );
  }

  renderItemAddToCart() {
    return (
      <ProductButtons
        {...this.props.availability}
        className="w-auto"
        buttons={this.props.buttons}
        wrapper={{
          as: "div",
          props: { className: "text-center", style: { width: "fit-content" } }
        }}
      />
    );
  }

  renderCompact() {
    const compareItemRemove = this.renderCompareItemRemove();
    const compareItemBody = this.renderItemBody();
    const thumbnail = this.renderThumbnail();
    const compareItemPrice = this.renderItemPrice();

    return (
      <Card
        id={ProductCategoryComparatorItemBS + "-" + this.props.id}
        className={this.getClassName(
          null,
          "text-muted border-light " +
            (this.props.className ? " " + this.props.className : "")
        )}
        style={{ width: this.props.width ? this.props.width : null }}
      >
        {compareItemRemove}
        <Container>
          <Row>
            <Col className="xyz" xs={12}>
              {thumbnail}
            </Col>
            <Col className="xyz" xs={12}>
              {compareItemBody}
              {compareItemPrice}
            </Col>
          </Row>
        </Container>
      </Card>
    );
  }

  render() {
    if (this.props.compact) {
      return this.renderCompact();
    }

    const compareItemRemove = this.renderCompareItemRemove();
    const compareItemBody = this.renderItemBody();
    const thumbnail = this.renderThumbnail();
    const compareItemPrice = this.renderItemPrice();
    const compareItemRating = this.renderItemRating();

    const addToCartButtons = this.props.showBuyButtons ? (
      <div className="d-flex justify-content-center">
        {this.renderItemAddToCart()}
      </div>
    ) : null;

    return (
      <Card
        id={ProductCategoryComparatorItemBS + "-" + this.props.id}
        className={this.getClassName(
          null,
          "text-muted border-light " +
            (this.props.className ? " " + this.props.className : "")
        )}
        style={{ width: this.props.width ? this.props.width : null }}
      >
        {compareItemRemove}
        <div className="d-flex flex-md-row align-items-center flex-column-reverse align-items-md-end">
          <div
            className="d-flex flex-column h-100 align-items-center text-center text-md-right align-items-md-end"
            style={{ justifyContent: "space-evenly" }}
          >
            <div>
              {compareItemBody}
              {compareItemRating}
            </div>
            <div>{compareItemPrice}</div>
          </div>
          <div>{thumbnail}</div>
        </div>
        {addToCartButtons}
      </Card>
    );
  }
}

ProductCategoryComparatorItem.propTypes = {
  ...ProductProps(),
  showThumbnail: PropTypes.bool,
  showBuyButtons: PropTypes.bool,
  compact: PropTypes.bool
};

ProductCategoryComparatorItem.defaultProps = {
  showThumbnail: true,
  showBuyButtons: false,
  compact: false
};

ProductCategoryComparatorItem.mapValueToProps = value => ({
  currency: value.currency
});

export default connectHOCs(ProductCategoryComparatorItem, { withSite: true });
