import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { TagButtonBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import ColorVariantType from "@prop-types/ColorVariantType";

const TagButton = React.memo(function TagButton(props) {
  const className = props.className || TagButtonBS;

  const buttons = [
    <Button
      key={0}
      id={props.id}
      variant={props.variant}
      onClick={props.onRemoveClick}
      size="sm"
      title={props.removeTitle}
      className={getComponentClassName(className, "item-remove")}
      data-active={props.active}
    >
      <FontAwesomeIcon icon="times" />
    </Button>,
    <Button
      key={1}
      variant={props.labelVariant}
      title={props.title}
      size="sm"
      disabled
      className={getComponentClassName(className, "item-label")}
    >
      {props.title}
    </Button>
  ];

  if (props.reverseButtons) {
    buttons.reverse();
  }

  return (
    <ButtonGroup
      aria-label={props.id}
      className={getComponentClassName(className, "item")}
      style={props.style}
    >
      {buttons}
    </ButtonGroup>
  );
});

TagButton.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  onRemoveClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  removeTitle: PropTypes.string,
  active: PropTypes.bool,
  variant: ColorVariantType(),
  labelVariant: ColorVariantType(),
  style: PropTypes.object,
  reverseButtons: PropTypes.bool
};

TagButton.defaultProps = {
  variant: "primary",
  labelVariant: "outline-secondary",
  reverseButtons: true
};

export default TagButton;
