import { getRoutePathByItem } from "@constants";
import { isMobileDevice, mediaBreakpoint } from "@utils/breakpoints";
import React from "react";
import ImageFootnoteCardDeck from "../../components/CardDeck/Image/Footnote";

export default ({
  className,
  imgParams,
  pathfinder,
  i18n,
  imageFootnoteCardDeck
}) => {
  const _i18n = i18n.pages.MainMenu;

  function transformItem(item) {
    const result = {
      caption: item.TITLE,
      url: getRoutePathByItem(pathfinder, item),
      css: item.CSS,
      position: item.POSITION
    };

    if (Array.isArray(item.items)) {
      result.dropdown = Boolean(item.DROPDOWN);
      result.hasAnchor = result.dropdown && item.ANCHOR;
      result.items = transformItems(item.items);
    } else {
      result.hasAnchor = item.ANCHOR;
    }

    const groups = transformGroups(item.groups);

    if (groups) {
      const groupItems = groups.filter(group => group.items);
      if (groupItems.length) {
        result.groups = groupItems;
      }
    }

    // if (item.URL) {
    //   if (/^([\w-]+)$/.test(item.URL)) {
    //     result.routeName = item.URL;
    //   } else {
    //     result.routeName = pathfinder.find(item.URL);
    //   }
    // } else {
    //   result.routeName = item.URL
    //     ? pathfinder.get(item.URL)
    //     : item.CATEGORY_SLUG
    //     ? pathfinder.find(
    //         pathfinder.prefixRoute("/product-category/:categoryId")
    //       )
    //     : null;
    // }

    return result;
  }

  function transformItems(items) {
    if (!(items && Array.isArray(items))) {
      return items;
    }

    return items.map(transformItem);
  }

  function transformGroups(groups) {
    if (!(groups && Array.isArray(groups))) {
      return groups;
    }

    return groups.map(group => {
      return {
        colspan: _i18n.HAS_FOOTNOTE_CARDDECK ? group.COLSPAN : null,
        items: transformItems(group.items)
      };
    });
  }

  const menu = _i18n.items.map(transformItem);

  if (_i18n.HAS_FOOTNOTE_CARDDECK && menu[0].groups) {
    const usedColSpan = menu[0].groups.reduce(
      (carry, group) => carry + (group.colspan || 1),
      0
    );

    menu[0].groups.push({
      colspan: 12 - usedColSpan,
      mediaQuery: mediaBreakpoint.default,
      isFootenoteCardDeck: true,
      items: (
        <ImageFootnoteCardDeck
          items={imageFootnoteCardDeck.items}
          asDropdownItem
          asOverlay={true}
          centered={true}
        />
      )
    });
  }

  return {
    menu,
    className,
    autoExpand: _i18n.AUTO_EXPAND && !isMobileDevice(),
    wideDropdown: Boolean(_i18n.WIDE_DROPDOWN),
    textWrap: Boolean(_i18n.TEXT_WRAP),
    backItemTitle: _i18n.BACK_ITEM_TITLE,
    parentItemPrefix: _i18n.PARENT_ITEM_PREFIX
  };
};
