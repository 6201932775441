import CollapsibleContainer from "@components-core/CollapsibleContainer";
import PureComponent from "@components-core/PureComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import { ProductCategoryComparatorSpecsBS } from "@style-variables";
import { optimalGridColWidth } from "@utils/breakpoints";
import { getComponentClassName, joinNonEmptyStrings } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default class ProductCategoryComparatorSpecs extends PureComponent {
  static SHOW_ALIKE = 1;
  static SHOW_DIFF = 2;

  applyFilter(values, filter) {
    const { show_alike, show_diff } = filter;

    let result = 0;

    if (show_alike) {
      if ([...new Set(values)].length === 1) {
        result = result | ProductCategoryComparatorSpecs.SHOW_ALIKE;
      }
    }

    if (show_diff) {
      if ([...new Set(values)].length > 1) {
        result = result | ProductCategoryComparatorSpecs.SHOW_DIFF;
      }
    }

    return result;
  }

  renderSpecsGroupItem(group, name, j, filter) {
    const items = this.props.items.map(product =>
      product.specs.items
        .filter(item => item.group === group)
        .reduce((carry, item) => carry.concat(...item.items), [])
        .filter(item => item.title === name)
    );

    const values = items.map(product =>
      product.reduce((carry, item) => carry.concat(...item.value), []).join(",")
    );

    const filterValue = this.applyFilter(values, filter);

    if (!filterValue) {
      return null;
    }

    const yes_no = items.map(product =>
      product.reduce(
        (carry, item) =>
          null !== carry ? carry : null !== item.boolean ? item.boolean : null,
        null
      )
    );

    const cols = values.map((value, i) => {
      const colWidth = optimalGridColWidth(
        {}, //{ xs: 6, sm: 4, md: 3, lg: 2 },
        values.length,
        i
      );

      const col = (
        <Col
          className={joinNonEmptyStrings(
            "text-center",
            yes_no[i] !== null
              ? yes_no[i]
                ? "text-success"
                : "text-danger"
              : null,
            " "
          )}
          style={{ minWidth: "200px" }}
          key={i}
          {...colWidth}
        >
          {yes_no[i] !== null ? (
            <FontAwesomeIcon
              icon={yes_no[i] ? "check-circle" : "times-circle"}
            />
          ) : "" === value ? (
            <FontAwesomeIcon className="text-muted" icon="question-circle" />
          ) : (
            value
          )}
        </Col>
      );

      if (!i) {
        return (
          <Col key={i} {...colWidth} style={{ minWidth: "200px" }}>
            <Container>
              <Row>
                <Col className="text-secondary">{name}</Col>
                {col}
              </Row>
            </Container>
          </Col>
        );
      }

      return col;
    });

    const className = ["flex-nowrap align-items-end"];

    if (
      (filterValue & ProductCategoryComparatorSpecs.SHOW_ALIKE) ===
      ProductCategoryComparatorSpecs.SHOW_ALIKE
    ) {
      //className.push("text-info");
    } else if (
      (filterValue & ProductCategoryComparatorSpecs.SHOW_DIFF) ===
      ProductCategoryComparatorSpecs.SHOW_DIFF
    ) {
      //className.push("text-danger");
    }

    if (0 === j % 2) {
      className.push("bg-light");
    }

    return (
      <Row
        className={className.join(" ")}
        key={j}
        style={{ minWidth: cols.length * 200 + "px" }}
      >
        {cols}
      </Row>
    );
  }

  getSpecsGroupWrapper() {
    return {
      as: Container,
      props: {
        fluid: true,
        className: getComponentClassName(
          "group-wrapper",
          null,
          "border border-light"
        )
      }
    };
  }

  renderSpecsGroup(group, filter) {
    let j = 0;
    const rows = group.items
      .map(name => {
        const row = this.renderSpecsGroupItem(group.name, name, j, filter);
        if (row) {
          j++;
        }
        return row;
      })
      .filter(v => null !== v);

    if (!rows.length) {
      return null;
    }

    const id = group.name.replace(/[^A-Za-z0-9_~]/g, "-");

    return (
      <CollapsibleContainer
        className={[id, ProductCategoryComparatorSpecsBS, "px-0"].join(" ")}
        id={id}
        title={group.name}
        collapsible
        collapsed={false}
        wrapper={this.getSpecsGroupWrapper()}
        style={{ minWidth: 200 * this.props.items.length + "px" }}
      >
        <Container className="px-0" fluid>
          {rows}
        </Container>
      </CollapsibleContainer>
    );
  }

  render() {
    return this.renderSpecsGroup(
      {
        name: this.props.groupName,
        items: this.props.groupItems
      },
      { show_alike: this.props.showAlike, show_diff: this.props.showDiff }
    );
  }
}

ProductCategoryComparatorSpecs.propTypes = {
  groupName: PropTypes.string,
  ...ItemsAwareProps(true, "groupItems", PropTypes.string),
  ...ItemsAwareProps(),
  showAlike: PropTypes.bool,
  showDiff: PropTypes.bool
};
