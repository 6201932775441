import { connectHOCs } from "@components-utils";
import {
  PRODUCT_SELECTOR_TYPE_SERIES,
  findPathBySelectorType
} from "@constants";
import { mediaBreakpoint } from "@utils/breakpoints";
import React from "react";
import MediaQuery from "react-responsive";
import ProductSerieList from "./List";

const ProductSerieListComponent = props => {
  return (
    <MediaQuery {...mediaBreakpoint.mobile}>
      {matches => {
        const items = (
          (matches ? props.i18n.mobile.items : props.i18n.desktop.items) || []
        )
          .filter(
            item =>
              item.IMG || item.PRODUCT_SERIES_SLUG || item.PRODUCT_SERIES_ID
          )
          .map((item, orderIndex) => {
            const categorySearchKey = [
              item.PRODUCT_SERIES_SLUG,
              item.PRODUCT_SERIES_ID
            ]
              .filter(Boolean)
              .join("-");

            return {
              title: item.TITLE,
              id: item.PRODUCT_SERIES_ID,
              url: findPathBySelectorType(
                props.pathfinder,
                PRODUCT_SELECTOR_TYPE_SERIES,
                categorySearchKey
              ),
              toggleCaption: item.TOGGLE_CAPTION,
              seo: {},
              summary: {},
              searchKey: categorySearchKey,
              categoryId: item.PRODUCT_SERIES_ID,
              categorySearchKey,
              orderIndex,
              itemsPerRow: item.ITEMS_PER_ROW,
              img: {
                ...props.imgBuilder(item.IMG, matches),
                importance: orderIndex ? undefined : "high"
              }
            };
          });

        const header = {
          visible:
            props.i18n.SHOW_MORE &&
            (matches
              ? props.i18n.mobile.MAX_VISIBLE_ITEMS
              : props.i18n.desktop.MAX_VISIBLE_ITEMS),
          showMore: props.i18n.SHOW_MORE,
          title: props.i18n.TITLE
        };

        return (
          <ProductSerieList
            {...{
              ...props,
              header,
              items: items.length ? items : props.items
            }}
          />
        );
      }}
    </MediaQuery>
  );
};

ProductSerieListComponent.defaultProps = {};

ProductSerieListComponent.mapValueToProps = value => {
  return {
    imgDir: value.imgDir,
    cloudinary: value.cloudinary,
    i18n: value.i18n.pages.ProductSeries,
    imgBuilder: (imgSrc, mobile) => ({
      src: value.imgResolver(
        value.imgDir,
        (imgSrc || "").replace(
          new RegExp(`^v\\d+\\/${value.cloudinary.path}\\/${value.imgDir}(.+)`),
          "$1"
        )
      ),
      cloudinary: value.cloudinary,
      aspect: mobile ? 0.8138 : 0.4348,
      sizes: {
        mobilePortrait: 376,
        mobileLandscape: 844,
        tabletPortrait: 1024,
        tabletLandscape: 1190,
        desktop: 1424,
        any: null
      },
      thumbnail: false
      //thumbnail: trademark.imgThumbnail || trademark.thumbnail
    })
  };
};

export default connectHOCs(ProductSerieListComponent, {
  withSite: true,
  withGraphQL: true
});
