import JSXProps from "@prop-types/JSXProps";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Jumbotron, Row } from "react-bootstrap";

const LoremIpsum = ({ className, title, text }) => {
  return (
    <div className="m-5">
      <Jumbotron>
        <Container fluid>
          <Row>
            <Col>
              <h1>{title}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>{text}</div>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    </div>
  );
};

export default LoremIpsum;

LoremIpsum.propTypes = {
  title: PropTypes.string.isRequired,
  text: JSXProps(),
  className: PropTypes.string
};

LoremIpsum.defaultProps = {
  title: "Lorem ipsum dolor sit amet",
  className: "text-info",
  text: (
    <React.Fragment>
      <p className="m-4">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed risus nibh,
        imperdiet id augue lacinia, dictum fringilla massa. Sed non velit vel
        lorem cursus ultricies. Integer tempus scelerisque eros. Nunc feugiat
        nunc ac urna maximus pulvinar. In vitae scelerisque libero. Interdum et
        malesuada fames ac ante ipsum primis in faucibus. Nulla a diam non justo
        commodo tempus quis nec arcu. Nam non est at magna consectetur pretium.
        Donec auctor at turpis in porttitor.
      </p>
      <p className="m-4">
        Curabitur id metus elit. Curabitur semper ultricies lorem vitae
        molestie. Nullam fermentum odio quis interdum varius. Aenean sodales
        arcu at enim blandit convallis. Vivamus pulvinar scelerisque lectus,
        venenatis iaculis leo bibendum pharetra. Etiam facilisis massa ultricies
        purus dictum, vel accumsan ante condimentum. Nam venenatis facilisis
        consequat. Sed sit amet ante vel dui lacinia laoreet. Proin et magna non
        dui tempus rutrum non non nisi.
      </p>
    </React.Fragment>
  )
};
