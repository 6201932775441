import { PAGE_KEY_ASSEMBLY_GUIDE } from "@constants";
import { mergeTemplateItems } from "../common/utils";
import { createLazyTemplateItem } from "../utils";

const TEMPLATE_KEY = "sites/AssemblyGuideTemplate-Wine";

const AssemblyGuideTemplate = props => {
  const transformOtherParagraphs = items =>
    items.map(item => ({ component: "core/TitledParagraph", props: item }));

  const defaultTemplate = [
    {
      component: "core/Media",
      props: {
        ...props.assemblyGuide.lead,
        as: "h2",
        preload: true,
        className: "mb-3"
      }
    },
    {
      component: "Newspaper/Jump",
      props: props.assemblyGuide.installChecklist
    },
    {
      component: "core/MediaEmbederList",
      props: props.assemblyGuide.mediaList
    },
    ...transformOtherParagraphs(props.assemblyGuide.otherParagraphs.items),
    // {
    //   component: "ProductCategory/Featured",
    //   props: props.searchBanner
    // },
    ...props.widgets
  ];

  return mergeTemplateItems(defaultTemplate, props.templates).map(
    createLazyTemplateItem
  );
};

AssemblyGuideTemplate.mapValueToProps = value => ({
  assemblyGuide: value.assemblyGuide,
  searchBanner: value.searchBanner
});

AssemblyGuideTemplate.mapValueToTemplateProps = {
  pageKey: PAGE_KEY_ASSEMBLY_GUIDE,
  templateKey: TEMPLATE_KEY
};

export default AssemblyGuideTemplate;
