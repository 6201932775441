import ImageSlider from "@components-core/ImageSlider";
import { withPlaceholder } from "@components-core/Placeholder";
import ColumnWidthType from "@prop-types/ColumnWidthType";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import { ProductPackageBS } from "@style-variables";
import { actualDeviceBreakpoint } from "@utils/breakpoints";
import { getProductDiscount } from "@utils/functions";
import { calcImgMaxSize, getSliderImgSize } from "@utils/image";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { imgSVG } from "../../sites/_default-img";
import { renderImageThumbnail as _renderImageThumbnail } from "../ProductCategory/utils";
import ProductTitle from "./Title";

const ProductThumbnail = props => {
  const renderImageThumbnail = _renderImageThumbnail({
    productItem: props.productItem,
    placeholder: props.placeholder,
    imgSize: props.imgSize
  });

  const discount = getProductDiscount(props.productItem);

  const thumbnail =
    props.productItem.img || props.placeholder
      ? renderImageThumbnail(discount)
      : null;

  return thumbnail;
};

const ProductPackageSlider = props => {
  const imgSize = calcImgMaxSize(props.items, props.colspan);

  const _imgSVG = imgSVG();

  const getImageSliderColspan = () => {
    switch (actualDeviceBreakpoint()) {
      case "mobilePortrait":
        return 1;
      case "mobileLandscape":
        return 2;
      case "tabletPortrait":
      case "tabletLandscape":
        return 3;
      case "desktop":
      default:
        return 4;
    }
  };

  const itemsPerSlide = getImageSliderColspan();
  const colSpan = Math.floor(12 / itemsPerSlide);

  const thumbnails = props.items.map((item, i) => ({
    ...item,
    title: item.title,
    img: {
      ...item.img,
      // enforce image thumbnail
      video: false,
      sizes: { desktop: 110, mobile: 80, tablet: 80 },
      imgSize: {
        maxWidth: 110,
        objectFit: "contain"
      }
    },
    loading: "lazy"
  }));

  for (let i = 0; i < thumbnails.length % itemsPerSlide; i + 1) {
    thumbnails.push({
      className: "",
      title: "",
      img: null,
      loading: "lazy"
    });
  }

  return (
    <ImageSlider
      activeIndex={0}
      prefetch
      preload={false}
      itemsPerSlide={itemsPerSlide}
      fitItemsPerSlide={false}
      colSpan={colSpan /*{ xs: colSpan, sm: colSpan, md: colSpan }*/}
      items={thumbnails}
      className={getComponentClassName(ProductPackageBS)}
      onClick={null}
      onRender={(
        productItem,
        index,
        { picture, caption, to, imgProps, style, title }
      ) => {
        if (productItem.img === null) {
          return null;
        }

        return (
          <ProductPackageItem
            productItem={productItem}
            title={productItem.title}
            subtitle={productItem.subtitle}
            placeholder={false}
            imgSize={imgSize}
          />
        );
      }}
      alignCenter
      indicators={false}
      controls
      prevIcon={_imgSVG.sliderArrowLeft}
      nextIcon={_imgSVG.sliderArrowRight}
      squareIcon={true}
      roundIcon={false}
      iconSize="3rem"
      iconPadding="0.5rem"
    />
  );
};

const ProductPackageItem = props => {
  const buttons = props.productItem.buttons;

  return (
    <Container className={"item"}>
      <Row>
        <Col>
          <ProductThumbnail
            productItem={props.productItem}
            placeholder={props.placeholder}
            imgSize={props.imgSize}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={"brand-title"}>
            {withPlaceholder(
              props.placeholder,
              props.productItem.brand
                ? props.productItem.brand.title
                : null || <br />
            )}
          </div>
          <ProductTitle
            as="h3"
            title={props.title}
            placeholder={props.placeholder}
            onClick={buttons ? buttons.product.onClick : null}
            href={buttons ? buttons.product.href : null}
          />
        </Col>
      </Row>
    </Container>
  );
};

const ProductPackage = props => {
  const imgSize = calcImgMaxSize(props.items, props.colspan);

  const sliderImgSize = getSliderImgSize(props.colspan, false);

  const items = props.items.map(item => {
    if (!item.img || !item.img.sizes) {
      return item;
    }

    return {
      ...item,
      img: {
        ...item.img,
        sizes: sliderImgSize
      }
    };
  });

  if (items.length > 4) {
    return <ProductPackageSlider colspan={props.colspan} items={items} />;
  }

  const _items = items.map((productItem, i) => (
    <Col key={i}>
      <ProductPackageItem
        productItem={productItem}
        title={productItem.title}
        subtitle={productItem.subtitle}
        placeholder={false}
        imgSize={imgSize}
      />
    </Col>
  ));

  return (
    <Container className={getComponentClassName(ProductPackageBS)}>
      <Row>{_items}</Row>
    </Container>
  );
};

ProductPackage.propTypes = {
  supportsTagDiscount: PropTypes.bool,
  colspan: ColumnWidthType(),
  ...ItemsAwareProps()
};

ProductPackage.defaultProps = {
  colspan: 4
};

export default ProductPackage;
