import RouteLink from "@components-core/RouteLink";
import { connectHOCs } from "@components-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { MenuBarItemBS, MenuBarWideBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React, { useState } from "react";

const classNames = (...args) => args.filter(Boolean).join(" ");

const CaretDownIcon = () => (
  <FontAwesomeIcon icon="chevron-down" className="CaretDown" />
);

const ListItem = React.forwardRef(
  ({ className, children, title, ...props }, forwardedRef) => (
    <li>
      <NavigationMenu.Link asChild active={false}>
        <div className={classNames("ListItemLink", className)}>
          <RouteLink {...props} ref={forwardedRef}>
            <div className="ListItemHeading">{title}</div>
          </RouteLink>
          <p className="ListItemText">{children}</p>
        </div>
      </NavigationMenu.Link>
    </li>
  )
);
ListItem.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
};

const MenuBarItemGroup = props => {
  if (!props.items.length) {
    return null;
  }

  const items = props.items.map((item, i) => (
    <ListItem
      key={i}
      title={item.caption}
      href={item.hasAnchor ? item.url : null}
    >
      {item.dropdown ? (
        <ul style={{ listStyleType: "none" }} className="pl-1">
          {item.items.map(({ caption, url, css }, j) => (
            <li key={j} className={props.activeUrl === url ? "active" : null}>
              <RouteLink url={url} className={css}>
                {caption}
              </RouteLink>
            </li>
          ))}
        </ul>
      ) : null}
    </ListItem>
  ));

  return (
    <ul
      className="List text-nowrap"
      style={{
        width: "100vw",
        // minWidth: "60vw",
        gridAutoFlow: "column",
        gridTemplateRows: "repeat(1, 1fr)"
      }}
    >
      {items}
    </ul>
  );
};
MenuBarItemGroup.propTypes = {
  ...ItemsAwareProps(),
  activeUrl: PropTypes.string
};

const MenuBarHeader = props => {
  const hasActiveUrl = item =>
    item.url === props.pathname || (item.items || []).some(hasActiveUrl);

  const renderHeaderGroup = items =>
    items.map((item, i) => {
      if (item.items) {
        return (
          <NavigationMenu.Item
            key={i}
            className={[
              props.leftItemClassName,
              props.selectedItemIndex === i ? "selected" : null
            ]
              .filter(Boolean)
              .join(" ")}
            onMouseEnter={() => props.onHover(i)}
          >
            <NavigationMenu.Trigger
              className={classNames(
                "NavigationMenuTrigger",
                hasActiveUrl(item) ? "active" : null
              )}
            >
              <RouteLink
                url={item.hasAnchor ? item.url : null}
                className={item.css}
              >
                {item.caption}
              </RouteLink>
              {props.showTriggerIndicator ? (
                <CaretDownIcon aria-hidden />
              ) : null}
            </NavigationMenu.Trigger>
            <NavigationMenu.Content className="NavigationMenuContent">
              <MenuBarItemGroup {...item} activeUrl={props.pathname} />
            </NavigationMenu.Content>
          </NavigationMenu.Item>
        );
      }

      return (
        <NavigationMenu.Item key={i} className={props.rightItemClassName}>
          <RouteLink
            {...item}
            className={classNames(
              item.className,
              "NavigationMenuLink",
              [props.pathname === item.url ? "active" : null, item.css]
                .filter(Boolean)
                .join(" ")
            )}
          >
            {item.caption}
          </RouteLink>
        </NavigationMenu.Item>
      );
    });

  const leftItems = renderHeaderGroup(
    props.items.filter(
      ({ position }) => !position || position === MenuBarWide.POSITION_LEFT
    )
  );

  const rightItems = renderHeaderGroup(
    props.items.filter(
      ({ position }) => position === MenuBarWide.POSITION_RIGHT
    )
  );

  return (
    <>
      <div className="left-items">{leftItems}</div>
      <div className="right-items">{rightItems}</div>
    </>
  );
};
MenuBarHeader.propTypes = {
  pathname: PropTypes.string,
  selectedItemIndex: PropTypes.number,
  leftItemClassName: PropTypes.string,
  rightItemClassName: PropTypes.string,
  onHover: PropTypes.func,
  ...ItemsAwareProps()
};

const MenuBarWide = props => {
  const [activeItem, setActiveItem] = useState("");
  const [selectedItem, setSelectedItem] = useState(-1);
  const [activeItemTime, setActiveItemTime] = useState(0);

  const leftItemClassName = getComponentClassName(MenuBarItemBS, null);
  const rightItemClassName = getComponentClassName(MenuBarItemBS);

  return (
    <NavigationMenu.Root
      value={activeItem}
      onValueChange={value => {
        const time = new Date();
        if (time - activeItemTime > 300) {
          setActiveItem(value);
          setActiveItemTime(new Date());
        }
      }}
      className={getComponentClassName(
        MenuBarWideBS,
        null,
        ["NavigationMenuRoot", props.className].filter(Boolean).join(" ")
      )}
      style={{ width: "100%" }}
    >
      <NavigationMenu.List className="NavigationMenuList">
        <MenuBarHeader
          items={props.items}
          pathname={props.location.pathname}
          selectedItemIndex={selectedItem}
          leftItemClassName={leftItemClassName}
          rightItemClassName={rightItemClassName}
          onHover={index => setSelectedItem(index)}
        />

        {props.showIndicator ? (
          <NavigationMenu.Indicator className="NavigationMenuIndicator">
            <div className="Arrow" />
          </NavigationMenu.Indicator>
        ) : null}
      </NavigationMenu.List>

      <div className="ViewportPosition">
        <NavigationMenu.Viewport className="NavigationMenuViewport" />
      </div>
    </NavigationMenu.Root>
  );
};
MenuBarWide.propTypes = {
  ...ItemsAwareProps(),
  showSelectedTrigger: PropTypes.bool,
  showIndicator: PropTypes.bool,
  showTriggerIndicator: PropTypes.bool
};

MenuBarWide.defaultProps = {
  showSelectedTrigger: true,
  showIndicator: false,
  showTriggerIndicator: false
};

MenuBarWide.POSITION_LEFT = "left";
MenuBarWide.POSITION_RIGHT = "right";

export default connectHOCs(MenuBarWide, { withRouter: true });
