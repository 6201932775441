import {
  CHECKOUT_FETCH_SHIPMENT_METHODS_FAILURE,
  CHECKOUT_FETCH_SHIPMENT_METHODS_REQUEST,
  CHECKOUT_FETCH_SHIPMENT_METHODS_SUCCESS,
  CHECKOUT_SET_SHIPMENT_METHOD
} from "../actionTypes";

const checkoutShipment = (state = {}, action) => {
  const { shipmentMethod } = action;

  switch (action.type) {
    case CHECKOUT_SET_SHIPMENT_METHOD:
      return { ...state, shipmentMethod };
    default:
      return state;
  }
};

const checkoutShipmentMethodsResult = (state, action) => {
  const getMethods = () => {
    // see issue #186
    const mostExpensiveLine = action.cart
      ? action.cart.reduce(
          (carry, item) =>
            !carry ||
            carry.product.newPrice * carry.quantity <
              item.product.newPrice * item.quantity
              ? item
              : carry,
          null
        )
      : null;

    const mostExpensiveItem = (mostExpensiveLine || {}).product;

    // the the most expensive item is branded
    if (mostExpensiveItem && mostExpensiveItem.brand.id > 0) {
      // include only the exclusive branded shipment methods
      const mostExpensiveItemShipmentMethods = action.methods.filter(
        method => +mostExpensiveItem.brand.id === +method.brandId
      );

      // in case we found at least one such exclusive branded method
      if (mostExpensiveItemShipmentMethods.length > 0) {
        return mostExpensiveItemShipmentMethods;
      }
    }

    // otherwise return all generic (non-branded) methods
    return action.methods.filter(method => !method.brandId);
  };

  const newState = {
    methods: null,
    cart: null,
    error: null,
    isFetching: false
  };

  switch (action.type) {
    case CHECKOUT_FETCH_SHIPMENT_METHODS_REQUEST:
      return { ...newState, isFetching: true };
    case CHECKOUT_FETCH_SHIPMENT_METHODS_SUCCESS:
      return {
        ...newState,
        methods: getMethods(),
        isFetching: false
      };
    case CHECKOUT_FETCH_SHIPMENT_METHODS_FAILURE:
      return { ...newState, error: action.error.message };
    default:
      return state || newState;
  }
};

export default {
  checkoutShipment,
  checkoutShipmentMethodsResult
};
