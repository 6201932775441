import React from "react";

const customCheckmark = (props = {}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 130 158"
    preserveAspectRatio="xMinYMin meet"
    width="auto"
    height="100%"
    style={{
      fill: "none",
      stroke: "currentColor",
      strokeWidth: "32px",
      strokeLinecap: "round",
      strokeMiterlimit: 10,
      width: "auto",
      ...(props.style || {})
    }}
    {...props}
  >
    {props.title ? <title>{props.title}</title> : null}
    <g>
      <line x1="16" y1="74.67" x2="54.43" y2="142.19" />
      <line x1="57.25" y1="140.78" x2="113.57" y2="16" />
    </g>
  </svg>
);

const getIconByName = (name, props = {}) => {
  switch (name) {
    case "custom-checkmark":
      return customCheckmark(props);
    default:
      throw new Error(`Unknown icon name "${name}"`);
  }
};

const customIcons = (
  name,
  props = { height: "1em", display: "inline-block" }
) => {
  return getIconByName(name, props);
};

export { customIcons };
