import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
// import sliderArrowLeft from "../img/slider-arrow-left.svg";
// import sliderArrowRight from "../img/slider-arrow-right.svg";

const faIcon = (props = {}) => (
  <FontAwesomeIcon {...props} size={props.size || "2x"} />
);

const imgSVG = (props = {}) => ({
  sliderArrowLeft: faIcon({ icon: "chevron-left", ...props }),
  sliderArrowRight: faIcon({ icon: "chevron-right", ...props }),
  carouselArrowLeft: faIcon({ icon: "chevron-left", ...props }),
  carouselArrowRight: faIcon({ icon: "chevron-right", ...props })
});

const imgPNG = {};
const imgJPEG = {};

export { faIcon, imgSVG, imgPNG, imgJPEG };
