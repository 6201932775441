import { PRODUCT_SELECTOR_TYPE_RELATED, STOCK_STATUS_OUT } from "@constants";
import { cartAddProduct } from "@redux-actions/cart";
import {
  addProductFavorite,
  addProductFavoriteFailure,
  addProductFavoriteSuccess,
  //
  removeProductFavorite,
  removeProductFavoriteFailure,
  removeProductFavoriteSuccess
} from "@redux-actions/product";
import { pushProductClick } from "@redux-actions/tracking";
import { ProductSliderBS } from "@style-variables";
import { dateAdd, stripTime } from "@utils/date";
import { calcImgMaxSize, getSliderImgSize } from "@utils/image";
import { getComponentClassName } from "@utils/strings";
import { ProductUrlTransformer } from "./ProductUrl";

/**
 * @description Transform a product to a set of product button-related properties used for rendering the `ReadMore`, `Buy` and `Preorder` product buttons
 * @param {Object} product A product from `products` datastore
 * @param {Object} { siteId, i18n, pathfinder, graphqlClient, dispatch } The site dependent configuration required for binding the buttons to the right actions/url/translation
 * @returns {Object}
 */
const ProductButtonsTransformer = (
  product,
  {
    siteId,
    i18n,
    pathfinder,
    graphqlClient,
    dispatch,
    checkoutLocked,
    impressionList
  }
) => {
  const onBtnClick = (product, preorder = false) =>
    checkoutLocked
      ? null
      : dispatch(
          cartAddProduct(
            product,
            { siteId, graphqlClient, i18n },
            preorder //Buy=false, Preorder=true
          )
        );

  return {
    product: {
      title: i18n.BTN_READ_MORE,
      href: ProductUrlTransformer(product, pathfinder),
      onClick: event => dispatch(pushProductClick(product, impressionList))
    },
    buy: {
      title: i18n.components.Product.BTN_BUY,
      disabled: checkoutLocked,
      onClick: event => onBtnClick(product)
    },
    preorder: {
      title: i18n.components.Product.BTN_PREORDER,
      disabled: checkoutLocked,
      onClick: event => onBtnClick(product, true)
    }
  };
};

const ProductAvailabilityTransformer = (product, { i18n }) => {
  const onEtaLimit = dateAdd({ days: 120 });

  const availability = {
    inStock: product.inStock,
    stockBalance: product.stockBalance,
    eta: product.inStock
      ? null
      : product.validFrom
      ? new Date(product.validFrom)
      : onEtaLimit,
    validFrom: product.validFrom,
    validTo: product.validTo,
    className: "mb-1"
  };

  // https://bitbucket.org/erison/lsbolagen-front/issues/68
  if (!availability.inStock && product.fsEtaLimit) {
    const eta = availability.validFrom
      ? new Date(availability.validFrom)
      : onEtaLimit;

    if (eta >= stripTime(dateAdd({ days: product.fsEtaLimit }))) {
      const eta_month = Math.ceil(product.fsEtaLimit / 30);
      availability.validFrom =
        i18n.components.ProductAvailability.LABEL_AVAILABILITY_MONTHS.replace(
          "%MONTH%",
          eta_month
        );
    }
  }

  // see ProductStockStatus GQL type
  if (STOCK_STATUS_OUT === product.stockStatus) {
    availability.validFrom = null;
  }

  return availability;
};

const ProductFavoriteTransformer = (
  product,
  { siteId, userId, i18n, graphqlClient, dispatch, favoriteItems }
) => {
  const isFavorite = favoriteItems[product.id];

  return {
    isFavorite: isFavorite || product.favorite,
    onChange: (e, favorite) => {
      const resolver = {
        exec: favorite ? addProductFavorite : removeProductFavorite,
        onSuccess: (payload, result) =>
          favorite
            ? addProductFavoriteSuccess({
                ...payload,
                result: result.addProductFavorite
              })
            : removeProductFavoriteSuccess({
                ...payload,
                result: result.removeProductFavorite
              }),
        onError: favorite
          ? addProductFavoriteFailure
          : removeProductFavoriteFailure
      };

      const payload = { productId: product.id, favorite };
      const config = {
        siteId,
        userId,
        i18n,
        graphqlClient
      };

      dispatch(resolver.exec(payload, config))
        .then(result => dispatch(resolver.onSuccess(payload, result)))
        .catch(error => dispatch(resolver.onError(error)));
    }
  };
};

const ProductTransformer = (
  items,
  {
    siteId,
    userId,
    i18n,
    pathfinder,
    graphqlClient,
    selectorType,
    impressionList,
    getState,
    dispatch
  }
) => {
  const state = "function" === typeof getState ? getState() : null;

  const checkoutLocked = state
    ? (state.placeOrderResult.isFetching ||
        state.checkout.paymentState.initiated) &&
      !(
        //state.checkout.paymentState.additionalDetails ||
        (
          state.checkout.paymentState.success ||
          state.checkout.paymentState.failure
        )
      )
    : true;

  return items.map(product => ({
    ...product,
    buttons: ProductButtonsTransformer(product, {
      siteId,
      i18n,
      pathfinder,
      graphqlClient,
      dispatch,
      checkoutLocked,
      selectorType,
      impressionList
    }),
    favorite: ProductFavoriteTransformer(product, {
      siteId,
      userId,
      i18n,
      graphqlClient,
      dispatch,
      favoriteItems: state ? state.productFavoriteResult.items : []
    }),
    availability: ProductAvailabilityTransformer(product, { i18n })
  }));
};

const ProductSliderTransformer = (items, props) => {
  const {
    // siteId,
    // userId,
    i18n,
    pathfinder,
    // graphqlClient,
    selectorType,
    // impressionList,
    itemsPerSlide,
    className,
    title,
    titleAs,
    text
    // getState,
    // dispatch
  } = props;

  const _itemsPerSlide = itemsPerSlide || 5;

  const colspan = Math.round(12 / _itemsPerSlide);

  const imgSize = calcImgMaxSize(items, colspan);

  const sliderImgSize = getSliderImgSize(colspan, false);

  const _items = items.map(item => {
    if (!item.img || !item.img.sizes) {
      return item;
    }

    return {
      ...item,
      img: {
        ...item.img,
        sizes: sliderImgSize
      }
    };
  });

  const sliderProps = {
    indicators: false,
    controls: true,
    // different items have different image size, the slider however must define their common boudaries
    imgSize,
    itemsPerSlide: _itemsPerSlide,
    title:
      PRODUCT_SELECTOR_TYPE_RELATED === selectorType
        ? i18n.components.SiteRouteSlider.TITLE
        : title,
    titleAs,
    text,
    pathfinder,
    className: getComponentClassName(ProductSliderBS, "slider", className),
    items: ProductTransformer(_items, props).map(item => ({
      ...item,
      comparable: false
    }))
  };

  return sliderProps;
};

export {
  ProductAvailabilityTransformer,
  ProductButtonsTransformer,
  ProductFavoriteTransformer,
  ProductSliderTransformer,
  ProductTransformer
};
