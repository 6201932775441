import {
  matchBreakpoints,
  mediaBreakpoint,
  mediaBreakpointNames
} from "@utils/breakpoints";
import { isAdminConfig } from "@utils/functions";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

const sizes = Object.keys(mediaBreakpoint.size);

const ReadMoreHelp = props => {
  const tagClose = "</readmore>";
  const tagOpen = (attributes = {}) => {
    const attr = Object.keys(attributes)
      .map(key => `${key}="${String(attributes[key])}"`)
      .join(" ");
    return `<readmore${attr ? " " + attr : ""}>`;
  };

  return (
    <div className="my-1 p-2 border border-secondary">
      How to use the <code>{tagOpen()}</code> tag:
      <div className="m-2">
        <code>
          {tagOpen({
            title: props.title,
            class: props.className,
            "btn-class": props.btnClassname,
            variant: props.variant,
            media: props.media,
            help: true,
            "match-all": props.matchAll
          })}
        </code>
        <br />
        <span className="mx-2">...some HTML content here</span>
        <br />
        <code>{tagClose}</code>
      </div>
      where attributes are:
      <ul>
        <li>
          title: the button's title (default "{ReadMore.defaultProps.title}
          ")
        </li>
        <li>
          class: a Bootstrap or a custom CSS class name applied to the wrapper
          element(default "{ReadMore.defaultProps.className}")
        </li>
        <li>
          btn-class: a Bootstrap or a custom CSS class name applied to the
          button (default "{ReadMore.defaultProps["btn-class"]}")
        </li>
        <li>
          variant: a Bootstrap button variant (default "
          {ReadMore.defaultProps.variant}")
        </li>
        <li>
          media: a comma-delimited list of media breakpoints that when matches
          it shows the button otherwise the content (
          <span className="text-muted">
            {mediaBreakpointNames().sort().join(" | ")}
          </span>
          )
        </li>
        <li>
          match-all: when <code>true</code> the inner HTML content is shown only
          if ALL media breakpoints are matched, otherwise ANY of them (default "
          {String(ReadMore.defaultProps["match-all"])}")
        </li>
        <li>
          help: when <code>true</code> then it shows this help message
          (admin-only)
        </li>
      </ul>
    </div>
  );
};

const ReadMore = props => {
  const matchAll =
    props["match-all"] === "" ||
    props["match-all"] === "true" ||
    props["match-all"] === true;

  const breakpoints = (props.media || "")
    .split(",")
    .map(key =>
      sizes.includes(key) ? mediaBreakpoint.size[key] : mediaBreakpoint[key]
    )
    .filter(Boolean);

  const [visible, setVisible] = useState(false);
  const [matches, setMatches] = useState(!breakpoints.length);

  useEffect(() => {
    const handleResize = e => {
      const matches =
        !breakpoints.length || matchBreakpoints(breakpoints, matchAll);

      setMatches(matches);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  if (visible && matches) {
    const help =
      isAdminConfig() && (props.help === "" || props.help === "true");

    if (help) {
      return (
        <ReadMoreHelp
          matchAll={matchAll}
          title={props.title}
          className={props.className}
          media={props.media}
          variant={props.variant}
          btnClassname={props["btn-class"]}
        />
      );
    }

    return props.children;
  }

  return matches ? (
    <div className={props.className} style={{ textAlign: "center" }}>
      <Button
        variant={props.variant}
        className={props["btn-class"]}
        onClick={e => setVisible(!visible)}
      >
        {props.title}
      </Button>
    </div>
  ) : null;
};

ReadMore.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  media: PropTypes.string,
  help: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  "btn-class": PropTypes.string,
  "match-all": PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

ReadMore.defaultProps = {
  title: "Read more",
  className: "text-center",
  variant: "light",
  "btn-class": "px-4",
  "match-all": false
};

export default ReadMore;
