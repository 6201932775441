import PureComponent from "@components-core/PureComponent";
import RouteLink from "@components-core/RouteLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BaseButtonProps from "@prop-types/BaseButtonProps";
import { HasDropDownBS, MenuBarButtonBS } from "@style-variables";
import { debug } from "@utils/debug";
import { getComponentClassName, joinNonEmptyStrings } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Dropdown, Nav } from "react-bootstrap";

export const CustomMenuBarButton = props => (
  <span
    role="button"
    aria-label={props.title}
    className="d-inline-flex"
    style={{ width: 20, height: 21 }}
  >
    <svg
      version="1.1"
      viewBox="0 0 579 479"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
    >
      <g
        style={{
          fill: "none",
          stroke: "#000",
          strokeLinecap: "round",
          strokeMiterlimit: 10,
          strokeWidth: "20px"
        }}
      >
        {props.children}
      </g>
    </svg>
  </span>
);

/**
 * @description Menu header button component
 * @export
 * @class MenuBarButton
 * @extends {PureComponent}
 */
export default class MenuBarButton extends PureComponent {
  // when true apply border style
  static DEBUG = false;

  /**
   * @description Check whether the button has a dropdown attached
   * @returns {Boolean}
   * @memberof MenuBarButton
   */
  hasDropDown() {
    return (
      this.props.dropdown &&
      Array.isArray(this.props.dropdown) &&
      this.props.dropdown.length
    );
  }

  /**
   * @description Get the component CSS classname. It might be overriden by an extended component.
   * @returns {string}
   * @memberof MenuBarButton
   */
  getClassName() {
    return [
      this.props.className,
      MenuBarButtonBS,
      this.hasDropDown() ? HasDropDownBS : null
    ]
      .filter(Boolean)
      .join(" ");
  }

  /**
   * @description Get the button badge value
   * @returns {*}
   * @memberof MenuBarButton
   */
  getButtonBadgeValue() {
    if (this.props.icon && this.props.icon.badge) {
      return this.props.icon.badge.value;
    }
    return null;
  }

  /**
   * @description Render the button badge
   * @returns {JSX}
   * @memberof MenuBarButton
   */
  renderButtonBadge() {
    if (!this.props.icon) {
      return null;
    }

    const iconBadge = this.props.icon.badge;

    if (!iconBadge) {
      return null;
    }

    return (
      <span
        className={getComponentClassName(
          this.getClassName(),
          "badge",
          joinNonEmptyStrings(
            null,
            iconBadge.color ? "badge-" + iconBadge.color : null
          )
        )}
        style={iconBadge.style}
      >
        {this.getButtonBadgeValue()}
      </span>
    );
  }

  getDropdownItems() {
    return this.props.dropdown.map((item, i) => {
      if (item.isSeparator) {
        return <Dropdown.Divider key={i} />;
      }
      return (
        <Dropdown.Item key={i} href={item.url} disabled={item.disabled}>
          {item.title}
        </Dropdown.Item>
      );
    });
  }

  renderButtonDropdown(children) {
    if (!this.hasDropDown()) {
      return children;
    }

    const className = [HasDropDownBS, `${this.props.role}-dropdown`]
      .filter(Boolean)
      .join(" ");

    return (
      <Dropdown className={className} alignRight={true}>
        <Dropdown.Toggle as="div">{children}</Dropdown.Toggle>
        <Dropdown.Menu>{this.getDropdownItems()}</Dropdown.Menu>
      </Dropdown>
    );
  }

  renderButtonIcon() {
    return (
      <FontAwesomeIcon
        size="lg"
        {...this.props.icon}
        badge={null}
        title={this.props.tooltip || this.props.title}
      />
    );
  }

  /**
   * @description Renders the button children
   * @returns {JSX}
   * @memberof MenuBarButton
   */
  renderButtonChildren() {
    const titleClass = joinNonEmptyStrings(
      "title",
      this.props.inline ? "d-inline-block" : "d-block",
      " "
    );

    const Wrapper = props =>
      React.isValidElement(this.props.children) ? (
        <div {...props} />
      ) : (
        <span {...props} />
      );

    const title =
      this.props.showTitle === true ? (
        <Wrapper className={titleClass}>
          {this.props.children || this.props.title}
        </Wrapper>
      ) : this.props.showTitle === "wrapper" ? (
        <span className={titleClass}>&nbsp;</span>
      ) : null;

    const icon = React.isValidElement(this.props.icon.icon)
      ? this.props.icon.icon
      : this.renderButtonIcon();

    return (
      <React.Fragment>
        {icon}
        {this.renderButtonBadge()}
        {title}
      </React.Fragment>
    );
  }

  /**
   * @description Render the button
   * @returns {JSX}
   * @memberof MenuBarButton
   */
  renderButton() {
    if (this.props.onClick && this.props.href) {
      debug(
        `The button with title "${this.props.title}" has both the onClick and href properties set.`,
        "warn"
      );
    }

    const ariaProps = this.props.onClick
      ? {
          role: "navigation",
          "aria-label": this.props.title || this.props.href
        }
      : null;

    const defaultClassname = this.getClassName();
    const hAuto =
      defaultClassname
        .split(" ")
        .filter(Boolean)
        .findIndex(k => k.startsWith("h-")) !== -1
        ? null
        : "h-auto";

    return (
      <Nav.Link
        key={0}
        as="div"
        onClick={this.props.onClick}
        className={getComponentClassName(
          defaultClassname,
          null,
          [
            this.props.href ? null : this.props.role,
            MenuBarButton.DEBUG ? "border" : null,
            hAuto,
            this.props.showTitle === true ? null : "titleless",
            this.props.icon.badge ? "d-flex" : null
          ]
            .filter(Boolean)
            .join(" ")
        )}
        {...ariaProps}
        id={this.props.onClick ? this.props.id : null}
        title={this.props.title}
        disabled={this.props.disabled}
      >
        {this.renderButtonChildren()}
      </Nav.Link>
    );
  }

  render() {
    const button = this.renderButton();

    if (this.props.href) {
      return this.renderButtonDropdown(
        <RouteLink
          key={0}
          href={this.props.href}
          title={this.props.title}
          id={this.props.id}
          disabled={this.props.disabled}
          className={this.props.role}
        >
          {button}
        </RouteLink>
      );
    }

    return this.renderButtonDropdown(button);
  }
}

MenuBarButton.propTypes = {
  ...BaseButtonProps(),
  tooltip: PropTypes.string,
  showTitle: PropTypes.oneOf([true, false, "wrapper"]),
  className: PropTypes.string,
  inline: PropTypes.bool
};

MenuBarButton.defaultProps = {
  showTitle: true,
  inline: false
};
