import ColumnWidthType from "@prop-types/ColumnWidthType";
import ImageProps from "@prop-types/ImageProps";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import MaxSizeProps from "@prop-types/MaxSizeProps";
import { ImageSliderBS } from "@style-variables";
import {
  breakpointsToColspan,
  getMediaBreakpointTypes,
  mediaBreakpoint
} from "@utils/breakpoints";
import { SUPPORTED_VIDEO_EXT } from "@utils/image";
import PreloadHelper from "@utils/preload";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row, Carousel } from "react-bootstrap";
import MediaQuery from "react-responsive";
import { imgSVG } from "../../sites/_default-img";
import ImageLoadingType from "../prop-types/ImageLoadingType";
import TitleTextProps from "../prop-types/TitleTextProps";
import GenericCarousel from "./GenericCarousel";
import Picture from "./Picture";
import RouteLink from "./RouteLink";
import { escapeReact } from "@utils/react";

const helper = new PreloadHelper();

function ImageSlider(props) {
  if (!props.items.length) {
    return null;
  }

  helper.setProps({
    ...props,
    // do not prefetch videos
    items: props.items.filter(
      item => item.img && !SUPPORTED_VIDEO_EXT.includes(item.img.extension)
    ),
    preloadCount: props.itemsPerSlide,
    prefetchCount: props.itemsPerSlide
  });
  helper.mountLinks();

  const renderItems = colspan =>
    props.items
      .map((item, i) =>
        !item.img && item.src
          ? {
              className: item.className,
              title: item.title,
              img: {
                src: item.src,
                url: item.href,
                cloudinary: item.cloudinary,
                video: item.video,
                sizes: item.sizes
              },
              aspect: item.aspect,
              version: item.version,
              imgSize: item.imgSize,
              url: item.href,
              caption: item.caption
            }
          : item
      )
      .map((item, index) => {
        const style =
          Number.isFinite(props.inactiveOpacity) &&
          Number.isInteger(props.activeIndex)
            ? {
                opacity: index === props.activeIndex ? 1 : props.inactiveOpacity
              }
            : null;

        let imgProps = {
          title: item.title,
          aspect: item.aspect,
          version: item.version,
          loading:
            item.loading ||
            "lazy" /*index >= colspan ? props.loading : "eager"*/
        };

        if (typeof item.img === "string") {
          imgProps.src = item.img;
        }
        if (typeof item.img === "object") {
          imgProps = {
            ...imgProps,
            ...item.img
          };
        }

        if (item.imgSize && typeof item.imgSize === "object") {
          if (item.imgSize.maxWidth || item.imgSize.maxHeight) {
            imgProps.imgSize = item.imgSize;
          }
        }

        const to = imgProps.url || item.href;

        const caption = item.caption ? (
          <Carousel.Caption key={"caption" + index}>
            {escapeReact(item.caption)}
          </Carousel.Caption>
        ) : null;

        const picture = (
          <Picture
            key={index}
            {...imgProps}
            onClick={e => {
              if ("function" === typeof props.onClick) {
                props.onClick(index);
              }
            }}
            style={style}
            placeholder={props.placeholder}
          />
        );

        let result =
          typeof props.onRender === "function"
            ? props.onRender(item, index, {
                picture,
                caption,
                to,
                imgProps,
                style,
                title: item.title
              })
            : [picture, caption];

        if (!to) {
          return result;
        }

        return (
          <RouteLink key={index} to={to} title={item.title}>
            {picture}
            {caption}
          </RouteLink>
        );
      });

  const _imgSVG_ = imgSVG();
  const _imgSVG = icon =>
    "function" === typeof _imgSVG_[icon]
      ? _imgSVG_[icon]({ styles: null })
      : _imgSVG_[icon];

  const nextIcon =
    "string" === typeof props.nextIcon
      ? _imgSVG(props.nextIcon)
      : props.nextIcon;

  const prevIcon =
    "string" === typeof props.prevIcon
      ? _imgSVG(props.prevIcon)
      : props.prevIcon;

  const useMediaQuery =
    props.colSpan &&
    typeof props.colSpan === "object" &&
    Object.keys(props.colSpan).some(k => getMediaBreakpointTypes().includes(k));

  const colSpan = props.colSpan
    ? "object" === typeof props.colSpan && Object.keys(props.colSpan).length
      ? props.colSpan
      : "number" === typeof +props.colSpan
      ? {
          mobilePortrait: 1,
          mobileLandscape: 2,
          tabletPortrait: +props.colSpan - 1,
          tabletLandscape: +props.colSpan,
          desktop: +props.colSpan
        }
      : null
    : null;

  const renderCarousel = breakpoint => {
    const colspan =
      breakpoint && colSpan[breakpoint]
        ? colSpan[breakpoint]
        : props.itemsPerSlide || props.colspan;

    const hasPrevNextIcons = props.items.length > colspan;

    const _props = {
      ...props,
      className: ImageSliderBS + " w-100 h-100",
      colSpan: useMediaQuery ? breakpointsToColspan(colSpan) : props.colSpan,
      itemsPerSlide: colspan,
      nextIcon: hasPrevNextIcons ? nextIcon : null,
      prevIcon: hasPrevNextIcons ? prevIcon : null,
      activeIndex: Math.floor(props.activeIndex / colspan)
    };

    return (
      <GenericCarousel
        key={"carousel"}
        {..._props}
        items={renderItems(colspan)}
        placeholder={props.placeholder}
      />
    );
  };

  const carousel = useMediaQuery ? (
    Object.keys(colSpan).map((breakpoint, i) => {
      return (
        <MediaQuery
          key={i}
          {...mediaBreakpoint[breakpoint]}
          className={props.className}
        >
          {renderCarousel(breakpoint)}
        </MediaQuery>
      );
    })
  ) : (
    <div className={props.className}>{renderCarousel()}</div>
  );

  const header = [];

  if (props.title || props.text) {
    if (props.title) {
      const TitleFactory = props.titleAs || "h2";

      header.push(
        <TitleFactory key={header.length}>{props.title}</TitleFactory>
      );
    }

    if (props.text) {
      header.push(
        <p className="my-3" key={header.length}>
          {"string" === typeof props.text
            ? escapeReact(props.text)
            : props.text}
        </p>
      );
    }

    return (
      <Container className="my-3">
        <Row>
          <Col>{header}</Col>
        </Row>
        <Row>
          <Col>{carousel}</Col>
        </Row>
      </Container>
    );
  }

  return carousel;
}

ImageSlider.propTypes = {
  ...TitleTextProps(),
  titleAs: PropTypes.string,
  ...ItemsAwareProps(
    false,
    null,
    PropTypes.shape({ ...ImageProps, label: PropTypes.string })
  ),
  itemsPerSlide: PropTypes.number,
  fitItemsPerSlide: PropTypes.bool,
  tightenItems: PropTypes.bool,
  colSpan: PropTypes.objectOf(ColumnWidthType()),
  className: PropTypes.string,
  onClick: PropTypes.func,
  onRender: PropTypes.func,
  imgSize: PropTypes.shape(MaxSizeProps),
  alignCenter: PropTypes.bool,
  controls: PropTypes.bool,
  nextIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  prevIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  activeIndex: PropTypes.number,
  inactiveOpacity: PropTypes.number,
  prefetch: PropTypes.bool,
  preload: PropTypes.bool,
  placeholder: PropTypes.bool,
  loading: ImageLoadingType()
};

ImageSlider.defaultProps = { prefetch: false, preload: true, loading: "lazy" };

export default ImageSlider;
