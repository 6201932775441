import MarqueeWidget from "@components-core/MarqueeWidget";
import PureComponent from "@components-core/PureComponent";
import { connectHOCs } from "@components-utils";
import {
  EVENT_MENU_PREVIEW,
  EVENT_MENU_TOGGLED,
  POSITION_FIXED_BOTTOM,
  POSITION_FIXED_TOP,
  POSITION_RELATIVE_BODY,
  POSITION_RELATIVE_TOP,
  SE_TAG_SEARCHBOX_ONLY,
  SE_TAG_SEARCHRESULTS_ONLY
} from "@constants";
import {
  fetchSiteNotifications,
  notificationFetchFailure,
  notificationFetchSuccess
} from "@redux-actions/notification";
import {
  fetchSiteRibbons,
  ribbonFetchFailure,
  ribbonFetchSuccess
} from "@redux-actions/ribbon";
import { LayoutFullWidthBS, PageHeaderBS } from "@style-variables";
import { mediaBreakpoint } from "@utils/breakpoints";
import { debug } from "@utils/debug";
import { createCustomEvent } from "@utils/dom";
import { isAdminConfig } from "@utils/functions";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import MediaQuery from "react-responsive";
import MenuBarHeader from "../MenuBar/Header";
import MenuBar from "../MenuBar/MenuBar";
import MenuBarBurger from "../MenuBar/MenuBarBurger";
import MenuBarWide from "../MenuBar/MenuBarWide";
import NotificationController from "../Notification/Controller";

// safely extract dynamically the custom search engine identifiers
const extractComponentProps = () => {
  const result = {};

  try {
    const {
      ID: CUSTOMSE_ID,
      DOCKED: CUSTOMSE_DOCKED
    } = require("../SearchEngine/Custom");

    result.CUSTOMSE_ID = CUSTOMSE_ID;
    result.CUSTOMSE_DOCKED = CUSTOMSE_DOCKED;
  } catch (error) {
    debug(error, "warn");
  }

  try {
    const {
      ID: GCSE_ID,
      DOCKED: GCSE_DOCKED
    } = require("../Google/SearchEngine");

    result.GCSE_ID = GCSE_ID;
    result.GCSE_DOCKED = GCSE_DOCKED;
  } catch (error) {
    debug(error, "warn");
  }

  return result;
};

const { CUSTOMSE_DOCKED, CUSTOMSE_ID, GCSE_DOCKED, GCSE_ID } =
  extractComponentProps();

// TODO this should embed a Helmet with meta-name="datastore" and content="the INT returned by datastoreTimestamp.gql"
class PageHeader extends PureComponent {
  static MOBILE_DEVICE = "mobile";
  static DESKTOP_DEVICE = "default";
  static SEARCH_ENGINE_GOOGLE = "google";
  static SEARCH_ENGINE_BUILTIN = "builtin";

  constructor(props) {
    super(props);

    this.fetchNotifications = this.fetchNotifications.bind(this);
    this.fetchRibbons = this.fetchRibbons.bind(this);
    this.handleMainMenuPreview = this.handleMainMenuPreview.bind(this);

    this.interval = null;

    this.state = { mainMenu: props.mainMenu };
  }

  UNSAFE_componentWillMount() {
    // since notifications are not vital on page mounting and since FCP/FID
    // is more vital let's delay the notification fetching/rendering by 3s
    // TODO injecting the notification bar on top of the header might affect the CLS
    setTimeout(() => {
      this.fetchNotifications(true);
    }, 0);

    // TODO: add exception for SSR user-agent
    this.fetchRibbons(true);
  }

  componentDidMount() {
    //this.mounted = true;

    if (this.props.refreshInterval) {
      this.interval = setInterval(
        this.fetchNotifications,
        this.props.refreshInterval * 1000
      );
    }

    window.addEventListener(EVENT_MENU_PREVIEW, this.handleMainMenuPreview);
  }

  componentWillUnmount() {
    //this.mounted = false;
    window.removeEventListener(EVENT_MENU_PREVIEW, this.handleMainMenuPreview);

    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  handleMainMenuPreview(e) {
    if (!(e.detail && typeof e.detail === "object")) {
      return;
    }

    const MainMenu = require("../../sites/common/MainMenu.js");

    const mainMenu = MainMenu.default({
      className: "menu-preview",
      pathfinder: this.props.pathfinder,
      imageFootnoteCardDeck: { items: [] },
      i18n: { pages: { MainMenu: e.detail } }
    });

    this.setState({ mainMenu });
  }

  /**
   * @description Fetch notifications from API server
   * @param {boolean} [force=false] When true force the notification fetch even if disabled
   * @memberof PageHeader
   */
  fetchNotifications(force = false) {
    if (!(force || this.props.notificationBar.enabled)) {
      return;
    }

    this.props
      .fetchSiteNotifications(this.props.siteConfig)
      .then(result => {
        this.props.notificationFetchSuccess(result.siteNotification);
      })
      .catch(error => {
        this.props.notificationFetchFailure(error, null, false);
        debug(error, "error");
      });
  }

  /**
   * @description Fetch site ribbons from API server
   * @param {boolean} [force=false] When true force the ribbons fetch even if disabled
   * @memberof PageHeader
   */
  fetchRibbons(force = false) {
    if (!(force || this.props.siteRibbons.enabled)) {
      return;
    }

    this.props
      .fetchSiteRibbons(this.props.siteConfig)
      .then(result => {
        this.props.ribbonFetchSuccess(result.siteRibbons);
      })
      .catch(error => {
        this.props.ribbonFetchFailure(error, null, false);
        debug(error, "error");
      });
  }

  /**
   * @description Render the menu bar
   * @returns {JSX}
   * @memberof PageHeader
   */
  renderMenuBar(isMobile) {
    if (this.state.mainMenu.menu) {
      if (this.state.mainMenu.wideDropdown) {
        if (isMobile) {
          return (
            <MenuBarBurger
              items={this.state.mainMenu.menu}
              autoExpand={this.state.mainMenu.autoExpand}
              wideDropdown={this.state.mainMenu.wideDropdown}
              textWrap={this.state.mainMenu.textWrap}
              backItemTitle={this.state.mainMenu.backItemTitle}
              parentItemPrefix={this.state.mainMenu.parentItemPrefix}
            />
          );
        }
        return (
          <MenuBarWide
            className={this.state.mainMenu.className}
            items={this.state.mainMenu.menu}
            autoExpand={this.state.mainMenu.autoExpand}
            wideDropdown={this.state.mainMenu.wideDropdown}
            textWrap={this.state.mainMenu.textWrap}
          />
        );
      }

      return (
        <MenuBar
          items={this.state.mainMenu.menu}
          autoExpand={this.state.mainMenu.autoExpand}
          wideDropdown={this.state.mainMenu.wideDropdown}
          textWrap={this.state.mainMenu.textWrap}
        />
      );
    }

    return null;
  }

  /**
   * @description Render the menu bar toggle
   * @returns {JSX}
   * @memberof PageHeader
   */
  renderNavbarToggle() {
    return {
      // title: this.props.i18n.pages.MainMenu.NAVBAR_TOGGLE_TITLE,
      icon: { icon: "bars" },
      onClick: e =>
        document.dispatchEvent(createCustomEvent(EVENT_MENU_TOGGLED)),
      position: "right"
    };
  }

  /**
   * @description Render the menu header (logo, buttons)
   * @returns {JSX}
   * @memberof PageHeader
   */
  renderMenuBarHeader(children) {
    const docked =
      PageHeader.SEARCH_ENGINE_BUILTIN === this.props.searchEngine
        ? CUSTOMSE_DOCKED
        : PageHeader.SEARCH_ENGINE_GOOGLE === this.props.searchEngine
        ? GCSE_DOCKED
        : false;

    return (
      <MenuBarHeader
        navbarToggle={this.renderNavbarToggle()}
        searchEngine={this.props.searchEngine}
        searchboxDocked={docked}
      >
        {children}
      </MenuBarHeader>
    );
  }

  /**
   * @description Render the header marquee
   * @returns {JSX}
   * @memberof PageHeader
   */
  renderMarquee() {
    return this.props.marquee && this.props.marquee.enabled === true ? (
      <MarqueeWidget {...this.props.marquee} />
    ) : null;
  }

  /**
   * @description Renders the search engine control
   * @param {Object} [gseWidget={}] The search engine widget configuration
   * @returns {JSX}
   * @memberof PageHeader
   */
  renderSearchEngine(gseWidget = {}) {
    const widgetId = gseWidget.predefined || gseWidget.id;

    const docked =
      widgetId === GCSE_ID
        ? false
        : widgetId === CUSTOMSE_ID
        ? CUSTOMSE_DOCKED
        : false;

    const getId = key =>
      [widgetId, key, gseWidget.identity].filter(Boolean).join("-");

    const inlineSearchboxRow = !docked ? (
      <Row>
        <Col id={getId(SE_TAG_SEARCHBOX_ONLY)} />
      </Row>
    ) : null;

    return (
      <Container fluid className="border-bottom">
        {inlineSearchboxRow}
        <Row>
          <Col id={getId(SE_TAG_SEARCHRESULTS_ONLY)} />
        </Row>
      </Container>
    );
  }

  /**
   * @description Renders the search engine for the given search engine widget ID
   * @param {String} id The search engine widget ID
   * @returns {JSX}
   * @memberof PageHeader
   */
  renderSearcEngineByWidgetId(id) {
    // assert the *SearchEngine widget is defined (not necessary enabled!) on footer-page widgets

    const se_widget = this.props.i18n.pages.FooterLinkSections.widgets.find(
      widget => id === widget.predefined
    );

    if (!se_widget) {
      debug(
        `No ${id} widget found on ${this.props.siteId}/frontend/i18n/pages/FooterLinkSections.json`,
        "warn"
      );

      return null;
    }

    if (se_widget.disabled) {
      return null;
    }

    return this.renderSearchEngine(se_widget);
  }

  /**
   * @description  Render the Google Search Engine
   * @returns {JSX}
   * @memberof PageHeader
   */
  renderGoogleSearchBox() {
    return this.renderSearcEngineByWidgetId(GCSE_ID);
  }

  /**
   * @description Render our built-in Search Engine
   * @returns {JSX}
   * @memberof PageHeader
   */
  renderBuiltInSearchBox() {
    return this.renderSearcEngineByWidgetId(CUSTOMSE_ID);
  }

  /**
   * @description Render the search box
   * @returns {JSX}
   * @memberof PageHeader
   */
  renderSearchBox() {
    switch (this.props.searchEngine) {
      case PageHeader.SEARCH_ENGINE_BUILTIN:
        return this.renderBuiltInSearchBox();
      case PageHeader.SEARCH_ENGINE_GOOGLE:
        return this.renderGoogleSearchBox();
      default:
        return null;
    }
  }

  /**
   * @description Get the default CSS classname
   * @param {string} [extra=""] Optional extra classes to append to the resulted classname
   * @returns
   * @memberof PageHeader
   */
  getClassname(extra = "") {
    const notificationBar = this.props.notificationBar;

    const { mountOnBody, isRelativeBody } = notificationBar;

    const x = ["justify-content-md-center", extra];

    if (mountOnBody && isRelativeBody) {
      x.push("position-relative-body");
    }

    return getComponentClassName(
      PageHeaderBS,
      null,
      x.filter(Boolean).join(" ")
    );
  }

  /**
   * @description Render the header content
   * @param {String} device The device type for which it renders
   * @param {JSX} [topNotifications=null] The notification shown on top
   * @param {JSX} [bottomNotifications=null] The notification shown on bottom
   * @returns {JSX}
   * @memberof PageHeader
   */
  renderHeader(device, topNotifications = null, bottomNotifications = null) {
    const isMobile = PageHeader.MOBILE_DEVICE === device;

    const marqueePosition = this.props.marquee
      ? this.props.marquee.position[device]
        ? this.props.marquee.position[device]
        : this.props.marquee.position.desktop
      : {};

    const marquee = this.renderMarquee();

    const fixedTopMarquee = POSITION_FIXED_TOP === marqueePosition;
    const fixedBottomMarquee = POSITION_FIXED_BOTTOM === marqueePosition;

    const relativeTopMarquee = POSITION_RELATIVE_TOP === marqueePosition;
    const relativeBodyMarquee = POSITION_RELATIVE_BODY === marqueePosition;

    const menuBarHeader = (mobile, children) =>
      mobile ? this.renderMenuBarHeader(children) : null;

    return (
      <React.Fragment key={1}>
        {relativeTopMarquee ? marquee : null}
        {menuBarHeader(isMobile, topNotifications)}
        {relativeBodyMarquee ? marquee : null}
        <Row className={this.getClassname()}>
          {fixedTopMarquee ? marquee : null}
          <div
            style={{
              //background: "linear-gradient(to top, #f2f2f2, #fafafa 70%)",
              width: "100%"
            }}
          >
            <div className={LayoutFullWidthBS}>
              {menuBarHeader(!isMobile, topNotifications)}
              {this.renderMenuBar(isMobile)}
            </div>
          </div>
          {fixedBottomMarquee ? marquee : null}
          {bottomNotifications}
          {this.renderSearchBox()}
        </Row>
      </React.Fragment>
    );
  }

  /**
   * @description Render the component by device type
   * @param {String} device The device type for which it renders
   * @returns {JSX}
   * @memberof PageHeader
   */
  renderDevice(device) {
    const notificationBar = this.props.notificationBar;

    const {
      mountOnBody,
      isScrollable,
      isFixedTop,
      isFixedBottom,
      isRelativeBody
    } = notificationBar;

    const notifications = (
      <NotificationController
        items={notificationBar.items}
        enabled={notificationBar.enabled}
        mountOnBody
        isScrollable
        isFixedTop
        isFixedBottom
        isRelativeBody
        key={0}
      />
    );

    const wrapperClassName = [
      "notification-bar-wrapper",
      "container-fluid",
      "px-0",
      isScrollable ? "scrollable" : null,
      isFixedTop ? "sticky-top" : null,
      isFixedBottom && mountOnBody ? "fixed-bottom" : null,
      isRelativeBody && mountOnBody ? "position-relative-body" : null
    ]
      .filter(Boolean)
      .join(" ");

    const topNotifications = isFixedBottom ? null : notifications;
    const bottomNotifications = isFixedBottom ? (
      <div className={wrapperClassName}>{notifications}</div>
    ) : null;

    if (mountOnBody) {
      const content = [topNotifications, this.renderHeader(device)];

      if (isFixedTop) {
        return (
          <MediaQuery {...mediaBreakpoint[device]}>
            <div className={wrapperClassName}>{content}</div>
          </MediaQuery>
        );
      } else if (isFixedBottom) {
        return (
          <MediaQuery {...mediaBreakpoint[device]}>
            {this.renderHeader(device)}
            {bottomNotifications}
          </MediaQuery>
        );
      } else if (isRelativeBody) {
        return (
          <MediaQuery {...mediaBreakpoint[device]}>
            {[this.renderHeader(device), topNotifications]}
          </MediaQuery>
        );
      } else {
        return <MediaQuery {...mediaBreakpoint[device]}>{content}</MediaQuery>;
      }
    }

    return (
      <MediaQuery {...mediaBreakpoint[device]}>
        {this.renderHeader(device, topNotifications, bottomNotifications)}
      </MediaQuery>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderDevice(PageHeader.MOBILE_DEVICE)}
        {this.renderDevice(PageHeader.DESKTOP_DEVICE)}
      </React.Fragment>
    );
  }
}

PageHeader.propTypes = {
  refreshInterval: PropTypes.number,
  searchEngine: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf([
      PageHeader.SEARCH_ENGINE_GOOGLE,
      PageHeader.SEARCH_ENGINE_BUILTIN
    ])
  ])
};

PageHeader.defaultProps = {
  refreshInterval: 3000
};

// ------------------- REDUX ----------------------
PageHeader.mapStateToProps = (state, ownProps) => {
  const adminConfig = isAdminConfig();

  const notificationBar = state.notificationBar;
  const { items, position } = notificationBar;

  // notification bar setup
  const mountOnBody =
    !items.length ||
    items.some(item => NotificationController.MOUNT_ON_BODY === item.mountOn);

  return {
    notificationBar: {
      ...notificationBar,
      items: items.filter(
        item =>
          (!item.adminOnly || adminConfig) &&
          !item._closed &&
          (mountOnBody
            ? NotificationController.MOUNT_ON_BODY
            : NotificationController.MOUNT_ON_HEADER) === item.mountOn
      ),
      isScrollable: items.some(
        item =>
          NotificationController.MOUNT_ON_BODY === item.mountOn && item.scroll
      ),
      isFixedTop: POSITION_FIXED_TOP === position,
      isFixedBottom: POSITION_FIXED_BOTTOM === position,
      isRelativeBody: POSITION_RELATIVE_BODY === position,
      mountOnBody
    },
    siteRibbons: state.siteRibbons.ribbons.filter(
      ribbon => !ribbon.adminOnly || adminConfig
    )
  };
};

PageHeader.mapDispatchToProps = {
  fetchSiteNotifications,
  notificationFetchSuccess,
  notificationFetchFailure,
  //
  fetchSiteRibbons,
  ribbonFetchFailure,
  ribbonFetchSuccess
};

PageHeader.mapValueToProps = value => {
  const adminConfig = isAdminConfig();

  const searchEngine =
    value.searchEngine.type &&
    value.footer.linkSection.widgets.some(widget => {
      const adminOnly =
        "undefined" === typeof value.i18n.components.SearchEngine.ADMIN_ONLY
          ? widget.adminOnly
          : value.i18n.components.SearchEngine.ADMIN_ONLY;

      return (
        [GCSE_ID, CUSTOMSE_ID].includes(widget.predefined) &&
        !widget.disabled &&
        (!adminOnly || adminConfig)
      );
    })
      ? value.searchEngine.type
      : false;

  return {
    mainMenu: value.mainMenu,
    marquee: value.marquee,
    searchEngine
  };
};

export default connectHOCs(PageHeader, {
  withSite: true,
  withConnect: true,
  withGraphQL: true
});
